<template>
    <table class="w-full mt-16">
        <tr class="text-left uppercase text-sm text-white bg-gray-dark">
            <th class="p-4">Kunde</th>
            <th class="p-4">Status</th>
            <th class="p-4">Aktionen</th>
        </tr>
        <template v-for="(daytour_order, index) in daytour_orders" :key="index">
            <tr :class="index % 2 == 0 ? 'bg-gray-lighter' : 'bg-white'" class="h-20">
                <td>
                    <div class="p-4">{{ daytour_order.order.customer.name }}</div>
                    <div class="p-4 text-sm">{{ daytour_order.order.customer.address }}</div>
                </td>
                <td>
                    <div class="p-4">
                        <font-awesome-icon v-if="daytour_order.status === 'done'" icon="fa-solid fa-check" class="text-green-500" />
                        <font-awesome-icon v-else-if="daytour_order.status === 'cancelled'" icon="fa-solid fa-xmark" class="text-red-500" />
                        <template v-else>
                            {{ daytour_order.status }}
                        </template>
                    </div>
                </td>
                <td>
                    <div class="p-4 border-l border-gray-light text-gray-dark flex justify-around items-center">
                        <div class="cursor-pointer" @click="daytour_order.isOpened = !daytour_order.isOpened">
                            <font-awesome-icon
                                icon="fa-solid fa-angle-down"
                                class="transition-transform duration-300"
                                :class="daytour_order.isOpened ? '-rotate-135' : ''"
                            />
                        </div>
                    </div>
                </td>
            </tr>
            <tr :id="'order-details-' + index" class="bg-gray-light" :class="daytour_order.isOpened ? '' : 'hidden'">
                <td colspan="3">
                    <customer-details-table
                        :customer="daytour_order.order.customer"
                        :order="daytour_order.order"
                        :daytour_order="daytour_order"
                        :show-transaction-restore-button="true"
                    />
                </td>
            </tr>
        </template>
    </table>
</template>

<script setup>
import { defineProps, toRefs } from "vue";
import CustomerDetailsTable from "../customers/CustomerDetailsTable.vue";

const props = defineProps({ daytour_orders: Object });

const { daytour_orders } = toRefs(props);
</script>

<style></style>
