<template>
    <div class="mb-4">
        <label :for="uuid" class="block uppercase tracking-wide text-xs font-bold mb-2">{{ label }}</label>
        <select
            :id="uuid"
            v-model="inputComputed"
            class="apperance-none block w-full bg-white text-black border py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-purple focus:ring-0 transition-colors duration-300 placeholder-gray"
            :class="error !== '' ? 'border-red-500' : 'border-gray'"
            :multiple="multiple"
            :disabled="disabled"
        >
            <slot />
        </select>
        <div v-if="error" class="text-red-500 text-sm text-right px-1">{{ error }}</div>
    </div>
</template>

<script setup>
import { onBeforeMount, defineProps, defineEmits, toRefs, computed } from "vue";

const emit = defineEmits(["update:input"]);
const props = defineProps({
    label: String,
    multiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    input: [String, Array, Number],
    error: String,
});

const { label, input, error } = toRefs(props);

const inputComputed = computed({
    get: () => input.value,
    set: (val) => emit("update:input", val),
});

let uuid = 0;
onBeforeMount(() => {
    uuid = Math.random().toString().substring(2);
});
</script>

<style></style>
