<template>
    <Skeletor v-if="!customers || !tour" height="60vh" />
    <admin-tour-table v-else :customers="customers" :tour_id="tour.id" />
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useTitleStore } from "@/store/title-store";

import AdminTourTable from "@/components/partials/tour/AdminTourTable.vue";

import axios from "axios";

const customers = ref(null);
const tour = ref(null);
const route = useRoute();
const titleStore = useTitleStore();

onMounted(async () => {
    getTourCustomers();
    tour.value = (await axios.get("tour/" + route.params.tour)).data;
    titleStore.setTitle("Tour - " + tour.value.name);
});

const getTourCustomers = async () => {
    customers.value = (await axios.get("tour_customers/" + route.params.tour)).data;
};
</script>
