<template>
    <Skeletor v-if="!customers" height="60vh" />
    <table v-else class="w-full">
        <tr class="text-left uppercase text-sm text-white bg-gray-dark">
            <th class="p-4">Kunde</th>
            <th class="p-4">Betrag</th>
            <th class="p-4">&nbsp;</th>
        </tr>
        <template v-for="(customer, index) in customers" :key="index">
            <tr :class="index % 2 == 0 ? 'bg-gray-lighter' : 'bg-white'" class="h-20">
                <td>
                    <div class="p-4">
                        {{ customer.name }}
                        <div class="text-sm">{{ customer.address }}</div>
                    </div>
                </td>
                <td>
                    <div class="p-4">
                        {{ currency.format(customer.diff) }}
                    </div>
                </td>
                <td>
                    <div class="p-4 border-l border-gray-light text-gray-dark flex justify-around items-center gap-4">
                        <round-button :icon="'fa-solid fa-info'" @click="router.push('/customer/' + customer.id)" />
                    </div>
                </td>
            </tr>
        </template>
        <tr class="bg-gray-dark text-white h-20">
            <td><div class="p-4">Gesamt</div></td>
            <td>
                <div class="p-4">{{ currency.format(total) }}</div>
            </td>
            <td></td>
        </tr>
    </table>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useTitleStore } from "@/store/title-store";
import { useRouter } from "vue-router";

import RoundButton from "@/components/global/RoundButton.vue";

import axios from "axios";
require("dayjs/locale/de");

const titleStore = useTitleStore();
const router = useRouter();

const total = ref(0);

const errors = ref([]);
const customers = ref(null);

const currency = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
});

const getCustomersWithOpenPayments = () => {
    errors.value = [];

    axios
        .get("customers/open_payments")
        .then((response) => {
            customers.value = response.data.customers;

            total.value = 0;
            customers.value.forEach((customer) => {
                total.value += parseFloat(customer.diff);
            });
        })
        .catch((error) => {
            if (error.response.status === 404) {
                errors.value["customers"] = error.response.data.message;
                customers.value = [];
            } else {
                console.log(error);
            }
        });
};

onMounted(async () => {
    titleStore.setTitle("Kunden im Zahlungsrückstand");

    getCustomersWithOpenPayments();
});
</script>
