<template>
    <div class="flex items-center gap-8 m-8 mb-4">
        <input
            type="text"
            id="customer-table-search"
            class="apperance-none block w-full bg-white text-black border py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-purple focus:ring-0 transition-colors duration-300 placeholder-gray border-gray"
            placeholder="Suche"
            ref="customerSearchInput"
            @input="customerSearch"
        />
        <round-button @click="emitter.emit('open-customer-modal', { name: customerSearchString })" :icon="'fa-solid fa-plus'" class="basis-9 shrink-0" />
    </div>

    <template v-if="showUnfiltered || customerSearchString !== ''">
        <Skeletor v-if="!customer_pagination" height="60vh" />
        <template v-else-if="customer_pagination.total === 0">
            <div class="flex justify-center mb-4 text-red-600">- nicht gefunden -</div>
        </template>
        <template v-else>
            <customers-table :customers="customer_pagination.highlightedData" />

            <div class="flex justify-center">
                <simple-pagination :pagination_data="customer_pagination" @next-page="nextPage" @prev-page="prevPage" />
            </div>
        </template>
    </template>
</template>

<script setup>
import { defineProps, inject, ref, toRefs } from "vue";
import axios from "axios";

import RoundButton from "@/components/global/RoundButton.vue";
import CustomersTable from "@/components/partials/customers/CustomersTable.vue";
import SimplePagination from "@/components/global/SimplePagination.vue";

const emitter = inject("emitter");
const props = defineProps({ showUnfiltered: { type: Boolean, default: true } });
const { showUnfiltered } = toRefs(props);

const customer_pagination = ref(null);
let currentCustomersApiCall = "customers";
const customerSearchInput = ref(null);
const customerSearchString = ref("");

let inputTimer = null;
const customerSearch = async () => {
    clearTimeout(inputTimer);
    customer_pagination.value = null;
    customerSearchString.value = customerSearchInput.value.value;

    if (customerSearchString.value.length > 0) {
        let converted_search_string = customerSearchString.value.replace(/\s/g, "+");
        currentCustomersApiCall = "customer_search?search=" + converted_search_string;
    } else {
        currentCustomersApiCall = "customers";
    }
    inputTimer = setTimeout(getCustomers, 500);
};

const getCustomers = () => {
    axios.get(currentCustomersApiCall).then((c) => {
        customer_pagination.value = c.data.customer_pagination;
        customer_pagination.value.highlightedData = highlightAllSearchOccurrences(customer_pagination.value.data);

        if(customer_pagination.value.highlightedData.length === 1) {
            customer_pagination.value.highlightedData[0].isOpened = true;
        }
    });
};
getCustomers();

const highlightSearchString = (stringToHighlight) => {
    if (stringToHighlight === null || stringToHighlight === "") {
        return stringToHighlight;
    }

    let result = stringToHighlight;
    const searchTerms = customerSearchString.value.trim().split(/\s+/);
    for (const term of searchTerms) {
        if (term !== "") {
            const regex = new RegExp(term, "gi");
            result = result.replace(regex, "<mark>$&</mark>");
        }
    }
    return result;
};

const highlightAllSearchOccurrences = (customers) => {
    return customers.map((c) => ({
        ...c,
        name__plain: c.name,
        name: highlightSearchString(c.name),
        // tour: { ...c.tour, name__plain: c.tour.name, name: highlightSearchString(c.tour.name) },
        address__plain: c.address,
        address: highlightSearchString(c.address),
        notes__plain: c.notes,
        notes: highlightSearchString(c.notes),
    }));
};

emitter.on("customer-saved", (customer) => {
    const index = customer_pagination.value.data.findIndex((c) => c.id === customer.id);

    if (index !== -1) {
        customer_pagination.value.data.splice(index, 1, customer);
        customer_pagination.value.highlightedData = highlightAllSearchOccurrences(customer_pagination.value.data);
    }
});

const nextPage = async () => {
    currentCustomersApiCall = customer_pagination.value.next_page_url;
    getCustomers();
};

const prevPage = async () => {
    currentCustomersApiCall = customer_pagination.value.prev_page_url;
    getCustomers();
};
</script>

<style></style>
