<template>
    <div class="bg-purple-dark min-h-10 text-white text-center">
        <div class="py-14">
            <a href="#">
                <font-awesome-icon icon="fa-solid fa-lock" class="mr-2" />
                &nbsp;Rinke Web-App
            </a>
        </div>
    </div>
</template>
