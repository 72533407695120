<template>
    <div class="menu-item">
        <template v-if="menuItem.children">
            <div @click="toggleSubMenu">
                {{ menuItem.text }}
                <font-awesome-icon icon="fa-solid fa-angle-down" :class="isOpened ? '-rotate-135' : ''" class="mx-2 transition-all duration-300" />
            </div>
            <div ref="subMenu" class="sub-menu hidden shadow-xl">
                <MenuItem v-for="(childItem, index) in menuItem.children" :key="index" :menuItem="childItem" @link-clicked="emit('link-clicked')" />
            </div>
        </template>
        <template v-else>
            <router-link :to="menuItem.path" @click="emit('link-clicked')">
                {{ menuItem.text }}
            </router-link>
        </template>
    </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, toRefs } from "vue";

import MenuItem from "./MenuItem.vue";

const emit = defineEmits(["link-clicked"]);

const props = defineProps({
    menuItem: {
        type: Object,
        default: () => ({ path: "", text: "" }),
        required: true,
    },
});

const { menuItem } = toRefs(props);
const subMenu = ref(null);
const isOpened = ref(false);

const toggleSubMenu = () => {
    isOpened.value = !isOpened.value;

    if (isOpened.value) {
        subMenu.value.classList.remove("hidden");
    } else {
        subMenu.value.classList.add("hidden");
    }
};
</script>
