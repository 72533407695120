<template>
    <div class="w-full relative">
        <div class="w-full px-8 fixed bg-purple z-20 text-white h-20">
            <div class="w-full max-w-4xl flex justify-between items-center mx-auto">
                <div class="py-4"><img src="/img/logo_underline.png" class="max-h-11" /></div>
                <main-menu v-if="isLoggedIn" />
            </div>
        </div>
        <div class="w-full bg-gray-light relative text-gray-dark pt-20">
            <h1 class="w-full max-w-4xl mx-auto px-8 text-center md:text-left text-xl font-bold py-5 uppercase">
                <template v-if="titleStore.title">
                    {{ titleStore.title }}
                </template>
                <Skeletor v-else />
            </h1>
        </div>
    </div>
</template>

<script setup>
import { useUserStore } from "@/store/user-store";
import { useTitleStore } from "@/store/title-store";

import MainMenu from "@/components/partials/MainMenu.vue";

const userStore = useUserStore();
const titleStore = useTitleStore();

const isLoggedIn = !!userStore.token;
</script>
