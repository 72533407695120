<template>
    <tr class="h-20 bg-gray-lighter">
        <td>
            <div class="p-4 flex flex-row">
                <div
                    v-if="daytour_order.status !== 'open'"
                    class="self-stretch basis-12 flex justify-center items-center shrink-0"
                    :class="daytour_order.status == 'done' ? 'text-green-500' : 'text-red-600'"
                >
                    <font-awesome-icon :icon="daytour_order.status == 'done' ? 'fa-solid fa-check' : 'fa-solid fa-xmark'" />
                </div>
                <div v-else-if="sortable" class="handle self-stretch basis-12 flex justify-center items-center shrink-0">
                    <font-awesome-icon icon="fa-solid fa-grip" />
                </div>
                <div v-else class="self-stretch basis-12 flex justify-center items-center shrink-0">&nbsp;</div>
                <div>
                    <div>{{ daytour_order.order.customer.name }}</div>
                    <div>{{ daytour_order.order.customer.address }}</div>
                    <div class="flex text-sm" v-if="daytour_order.order.customer.notes">
                        <div>
                            <font-awesome-icon icon="fa-solid fa-circle-info" class="mr-2" />
                        </div>
                        <div class="whitespace-pre-line">
                            {{ daytour_order.order.customer.notes }}
                        </div>
                    </div>
                </div>
            </div>
        </td>
        <td>
            <div class="p-4 border-l border-gray-light text-gray-dark flex justify-around items-center gap-4">
                <div class="cursor-pointer" @click="daytour_order.isOpened = !daytour_order.isOpened">
                    <font-awesome-icon
                        icon="fa-solid fa-angle-down"
                        class="transition-transform duration-300"
                        :class="daytour_order.isOpened ? '-rotate-135' : ''"
                    />
                </div>
            </div>
        </td>
    </tr>
    <tr class="bg-gray-light" :class="daytour_order.isOpened ? '' : 'hidden'">
        <td colspan="3">
            <div class="flex flex-col">
                <div class="mx-4 pt-4 flex">
                    <div class="self-stretch basis-12 flex justify-center items-center shrink-0">&nbsp;</div>
                    <div>
                        <font-awesome-icon icon="fa-solid fa-map" class="mr-2" />
                    </div>
                    <div>
                        <div v-for="(ordermeal, index) in daytour_order.order.ordermeals" :key="index" class="flex gap-2">
                            <div>
                                {{ ordermeal.quantity }}
                            </div>
                            <div>
                                <font-awesome-icon icon="fa-solid fa-xmark" class="text-sm" />
                            </div>
                            <div class="shrink">{{ ordermeal.meal.name }}</div>
                        </div>

                    </div>
                </div>
                <div class="mx-4 pb-4 flex" v-if="daytour_order.order.notes">
                    <div class="self-stretch basis-12 flex justify-center items-center shrink-0">&nbsp;</div>
                    <div>
                        <font-awesome-icon icon="fa-solid fa-circle-info" class="mr-2" />
                    </div>
                    <div v-if="daytour_order.order.notes" class="whitespace-pre-line">
                        {{ daytour_order.order.notes }}
                    </div>
                </div>
            </div>
        </td>
    </tr>
</template>

<script setup>
import { defineProps, toRefs } from "vue";

const props = defineProps({
    daytour_order: Object,
    sortable: {
        Type: Boolean,
        default: false,
    },
});
const { daytour_order, sortable } = toRefs(props);
</script>
