<template>
    <div
        class="rounded-full border-4 w-9 h-9 text-center transition-all duration-300"
        :class="disabled ? 'text-gray-light' : 'cursor-pointer text-white ' + additional_classes"
    >
        <font-awesome-icon :icon="icon" />
    </div>
</template>

<script setup>
import { defineProps, toRefs } from "vue";

const props = defineProps({
    icon: String,
    disabled: { type: Boolean, default: false },
    additional_classes: { type: String, default: "bg-purple-dark border-purple-dark" },
});

const { icon, disabled, additional_classes } = toRefs(props);
</script>
