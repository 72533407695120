<template>
    <table v-if="_daytour_orders.done && _daytour_orders.cancelled" class="w-full">
        <tbody v-for="(daytour_order, index) in [..._daytour_orders.done, ..._daytour_orders.cancelled]" :key="index">
            <admin-daytour-order-row :daytour_order="daytour_order" />
        </tbody>
    </table>
    <draggable
        class="list-group w-full"
        tag="table"
        :component-data="{
            type: 'transition-group',
        }"
        v-model="_daytour_orders.open"
        item-key="order"
        handle=".handle"
        :animation="250"
        @end="orderChanged"
    >
        <template #item="{ element }">
            <tbody>
                <admin-daytour-order-row :sortable="!isBeforeToday" :daytour_order="element" />
            </tbody>
        </template>
    </draggable>
    <button
        v-if="custom_sort_order"
        class="font-montserrat text-xs block w-full bg-purple hover:bg-black text-white rounded-sm py-3 transition-colors duration-300 uppercase tracking-widest font-bold"
        type="submit"
        @click="resetSortOrder"
    >
        <font-awesome-icon icon="fa-solid fa-user-edit" />
        &nbsp;Sortierung auf Standard zurücksetzen
    </button>
</template>

<script setup>
import axios from "axios";
import { defineProps, onMounted, ref, toRefs } from "vue";
import draggable from "vuedraggable";

import AdminDaytourOrderRow from "@/components/partials/daytour/AdminDaytourOrderRow.vue";

const props = defineProps({ daytour_orders: Object, isBeforeToday: Boolean });

const { daytour_orders, isBeforeToday } = toRefs(props);

const _daytour_orders = ref([]);

const custom_sort_order = ref(false);

onMounted(() => {
    _daytour_orders.value = daytour_orders.value;

    daytour_orders.value.open.forEach((daytour_order) => {
        if (daytour_order.sort_order) custom_sort_order.value = true;
    });
});

const orderChanged = () => {
    const sort_order = _daytour_orders.value.open.map((daytour_order) => {
        return daytour_order.id;
    });

    axios.post("daytour/sortorder?_method=PUT", { new_order: sort_order });

    custom_sort_order.value = true;
};

const resetSortOrder = async () => {
    const reset_ids = _daytour_orders.value.open.map((daytour_order) => {
        return daytour_order.id;
    });

    await axios.post("daytour/reset_sortorder?_method=PUT", { new_order: reset_ids });

    document.location.reload();
};
</script>
<style>
.fade-move,
.fade-enter-active,
.fade-leave-active {
    @apply transition-all duration-1000;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.fade-leave-to {
    @apply opacity-0 translate-x-full;
}
.fade-enter-from {
    @apply opacity-0 translate-y-full;
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.fade-leave-active {
    @apply absolute;
}
</style>
