<template>
    <div class="flex flex-col">
        <!-- <div class="mx-4 pt-4 flex" v-if="showTour">
            <div>
                <font-awesome-icon icon="fa-solid fa-map-marker-alt" class="mr-2" />
            </div>
            <div>
                <div v-html="customer.tour.name"></div>
            </div>
        </div> -->
        <div class="mx-4 pt-4 flex">
            <div>
                <font-awesome-icon icon="fa-solid fa-user" class="mr-2" />
            </div>
            <div>
                <div v-html="customer.name"></div>
                <div v-html="customer.address"></div>
                <div class="flex" v-if="customer.notes">
                    <div>
                        <font-awesome-icon icon="fa-solid fa-circle-info" class="mr-2" />
                    </div>
                    <div class="whitespace-pre-line" v-html="customer.notes"></div>
                </div>
            </div>
        </div>

        <template v-if="order">
            <div class="mx-4 pt-4 flex">
                <div>
                    <font-awesome-icon icon="fa-solid fa-map" class="mr-2" />
                </div>
                <div>
                    <div v-for="(ordermeal, index) in order.ordermeals" :key="index" class="flex gap-2">
                        <div>
                            {{ ordermeal.quantity }}
                        </div>
                        <div>
                            <font-awesome-icon icon="fa-solid fa-xmark" class="text-sm" />
                        </div>
                        <div class="shrink">{{ ordermeal.meal.name }}</div>
                    </div>
                    <div class="flex" v-if="order.notes">
                        <div>
                            <font-awesome-icon icon="fa-solid fa-circle-info" class="mr-2" />
                        </div>
                        <div v-if="order.notes" class="whitespace-pre-line">
                            {{ order.notes }}
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div class="mx-4 pt-4">
            <div class="py-4 border-t border-gray flex" :class="calculateCustomerBalance() < 0 ? 'text-red-500' : 'text-green-500'">
                <div>
                    <font-awesome-icon icon="fa-solid fa-money-bill-wave" class="mr-2" />
                </div>
                <div>
                    {{ customerBalanceString() }}
                </div>
            </div>
            <template v-if="showTransactionButtons">
                <new-order-button
                    :order="{
                        customer: customer,
                        options: { fixedcustomer: true },
                    }"
                />
                <new-payment-button
                    :payment="{
                        customer: customer,
                        options: { fixedcustomer: true },
                    }"
                />
            </template>
            <template v-if="daytour_order && showTransactionRestoreButton">
                <button
                    class="font-montserrat text-xs block w-full bg-purple hover:bg-black text-white py-3 mb-4 transition-colors duration-300 uppercase tracking-widest font-bold"
                    type="submit"
                    @click="emitter.emit('stop-restore', daytour_order)"
                >
                    <font-awesome-icon icon="fa-solid fa-check" />
                    Bestellung wiederherstellen
                </button>
            </template>
        </div>
    </div>
</template>

<script setup>
import { defineProps, inject, toRefs } from "vue";

import NewOrderButton from "@/components/global/buttons/NewOrderButton.vue";
import NewPaymentButton from "@/components/global/buttons/NewPaymentButton.vue";

const props = defineProps({
    customer: Object,
    order: Object,
    showTransactionButtons: Boolean,
    daytour_order: Object,
    showTransactionRestoreButton: Boolean,
});
const { customer, order, showTransactionButtons, daytour_order, showTransactionRestoreButton } = toRefs(props);

const emitter = inject("emitter");

const calculateCustomerBalance = () => {
    return 0;
    // const paymentsTotal = customer.value.payments.map((payment) => payment.amount).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    // const ordermealsTotal = customer.value.ordermeals
    //     .map((ordermeal) => ordermeal.quantity * ordermeal.meal.price)
    //     .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    // return Math.round((paymentsTotal - ordermealsTotal) * 100) / 100;
};

const customerBalanceString = () => {
    const result = calculateCustomerBalance();

    if (result < 0) {
        return "Offener Betrag: " + -result + " €";
    } else if (result === 0) {
        return "Kein ausstehender Betrag.";
    }

    return "Guthaben: " + result + " €";
};

emitter.on("customer-saved", (_customer) => {
    if (customer.value.id !== _customer.id) return;

    customer.value.address = _customer.address;
    customer.value.name = _customer.name;
    customer.value.notes = _customer.notes;
    // customer.value.tour = _customer.tour;
    // customer.value.tour_id = _customer.tour_id;
});

emitter.on("order-saved", (order) => {
    if (customer.value.id !== order.customer.id) return;

    if (customer.value.orders) {
        const index = customer.value.orders.findIndex((o) => o.id === order.id);
        if (index !== -1) {
            customer.value.orders[index] = order;
        } else {
            customer.value.orders.push(order);
        }
    }

    if (order.ordermeals) {
        order.ordermeals.forEach((ordermeal) => {
            const index = customer.value.ordermeals.findIndex((om) => om.id === ordermeal.id);

            if (index !== -1) {
                customer.value.ordermeals[index] = ordermeal;
            } else {
                customer.value.ordermeals.push(ordermeal);
            }
        });
    }
});

emitter.on("payment-saved", (payment) => {
    if (customer.value.id !== payment.customer.id) return;

    if (customer.value.payments) {
        const index = customer.value.payments.findIndex((p) => p.id === payment.id);
        if (index !== -1) {
            customer.value.payments[index] = payment;
        } else {
            customer.value.payments.push(payment);
        }
    }
});

emitter.on("order-deleted", (order) => {
    if (customer.value.id !== order.customer.id) return;

    if (customer.value.orders) {
        const index = customer.value.orders.findIndex((o) => o.id === order.id);
        if (index !== -1) {
            customer.value.orders.splice(index, 1);
        }
    }

    if (order.ordermeals) {
        order.ordermeals.forEach((ordermeal) => {
            const index = customer.value.ordermeals.findIndex((om) => om.id === ordermeal.id);

            if (index !== -1) {
                customer.value.ordermeals.splice(index, 1);
            }
        });
    }
});

emitter.on("payment-deleted", (payment) => {
    if (customer.value.id !== payment.customer.id) return;

    if (customer.value.payments) {
        const index = customer.value.payments.findIndex((p) => p.id === payment.id);
        if (index !== -1) {
            customer.value.payments[index] = payment;
        } else {
            customer.value.payments.push(payment);
        }
    }
});
</script>
