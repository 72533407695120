<template>
    <table class="w-full mt-4">
        <tr v-if="daytour_orders.length" class="text-left uppercase text-sm text-white bg-gray-dark">
            <th class="p-4">Kunde</th>
            <th class="p-4">Aktionen</th>
        </tr>
        <transition-group name="fade">
            <template v-if="daytour_orders.length == 0">
                <template v-if="totalOrderCount > 0">
                    <div v-if="isBeforeToday" class="text-center">
                        Diese Tour ist abgeschlossen!
                        <font-awesome-icon icon="fa-solid fa-check" class="text-green-500" />
                    </div>
                    <div v-else-if="isToday" class="text-center">
                        Die heutige Tour ist abgeschlossen!
                        <font-awesome-icon icon="fa-solid fa-check" class="text-green-500" />
                    </div>
                </template>
                <template v-else-if="totalOrderCount == 0">
                    <div v-if="isBeforeToday" class="text-center">Diese Tour wurde nicht geplant!</div>
                    <div v-else class="text-center">Diese Tour wurde noch nicht geplant!</div>
                </template>
            </template>
            <tbody v-else v-for="(daytour_order, index) in daytour_orders" :key="daytour_order.id">
                <daytour-order-row
                    :index="index"
                    :daytour_order="daytour_order"
                    :isToday="isToday"
                    :isEvenRow="index % 2 === 0"
                    :actionsEnabled="index === 0"
                />
            </tbody>
        </transition-group>
    </table>
</template>

<script setup>
import { defineProps, onMounted, onUpdated, toRefs } from "vue";

import DaytourOrderRow from "./DaytourOrderRow.vue";

const props = defineProps({ daytour_orders: Object, isToday: Boolean, isBeforeToday: Boolean, totalOrderCount: Number });

const { daytour_orders, isToday, isBeforeToday, totalOrderCount } = toRefs(props);

onMounted(() => {
    for (let i = 0; i < daytour_orders.value.length; i++) {
        if (i == 0) daytour_orders.value[i].isOpened = isToday.value;
        else daytour_orders.value[i].isOpened = false;
    }
});
onUpdated(() => {
    for (let i = 0; i < daytour_orders.value.length; i++) {
        if (i == 0) daytour_orders.value[i].isOpened = isToday.value;
        else daytour_orders.value[i].isOpened = false;
    }
});
</script>
<style>
.fade-move,
.fade-enter-active,
.fade-leave-active {
    @apply transition-all duration-1000;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.fade-leave-to {
    @apply opacity-0 translate-x-full;
}
.fade-enter-from {
    @apply opacity-0 translate-y-full;
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.fade-leave-active {
    @apply absolute;
}
</style>
