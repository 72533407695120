<template>
    <div>
        <customer-modal :customer="edit_customer" />
        <menu-modal :menu="edit_menu" />
        <order-modal :order="edit_order" />
        <payment-modal :payment="edit_payment" />
    </div>
</template>

<script setup>
import { ref, inject } from "vue";
import { Modal } from "flowbite";

import CustomerModal from "./modals/CustomerModal.vue";
import MenuModal from "./modals/MenuModal.vue";
import OrderModal from "./modals/OrderModal.vue";
import PaymentModal from "./modals/PaymentModal.vue";
import dayjs from "dayjs";
var _ = require("lodash");

const customer_defaults = {
    isOpened: true,
    tour_id: null,
    tour: {
        name: "",
    },
    name: "",
    address: "",
    note: "",
    options: {},
};
const menu_defaults = {
    isOpened: true,
    date: dayjs().add(7, "days"),
    meals: [
        {
            id: null,
            name: "",
            price: 8,
        },
    ],
};
const order_defaults = {
    isOpened: true,
    date: null, //dayjs(), //edit_date,
    customer: { id: null, name: "", address: "", note: "" },
    ordermeals: [{ meal: { id: null }, quantity: 1 }],
    note: "",
    options: { fixedcustomer: false, fixeddate: false },
};
const payment_defaults = {
    isOpened: true,
    amount: null,
    date: dayjs(), //dayjs().add(1, "day"),
    customer: { id: null, name: "", address: "", note: "" },
    payment_method: { id: null, name: "" },
    options: { fixedcustomer: false },
};

const edit_customer = ref(JSON.parse(JSON.stringify(customer_defaults)));
const edit_menu = ref(JSON.parse(JSON.stringify(menu_defaults)));
const edit_order = ref(JSON.parse(JSON.stringify(order_defaults)));
const edit_payment = ref(JSON.parse(JSON.stringify(payment_defaults)));

const modal_options = {
    placement: "top-center",
    backdrop: "static",
    backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
    closable: true,
};

let modal = null;
let customer_modal = null;
let menu_modal = null;
let order_modal = null;
let payment_modal = null;

const emitter = inject("emitter");

emitter.on("open-customer-modal", (customer) => {
    openCustomerModal(customer);
});
emitter.on("open-menu-modal", (menu) => {
    openMenuModal(menu);
});
emitter.on("open-order-modal", (order) => {
    openOrderModal(order);
});
emitter.on("open-payment-modal", (payment) => {
    openPaymentModal(payment);
});
emitter.on("close-modal", () => {
    closeModal();
});

const openCustomerModal = (customer) => {
    const $targetEl = document.getElementById("customer-modal");

    edit_customer.value = JSON.parse(JSON.stringify(customer_defaults));
    _.merge(edit_customer.value, customer);

    edit_customer.value.name = edit_customer.value.name__plain ? edit_customer.value.name__plain : edit_customer.value.name;
    edit_customer.value.tour.name = edit_customer.value.tour.name__plain ? edit_customer.value.tour.name__plain : edit_customer.value.tour.name;
    edit_customer.value.address = edit_customer.value.address__plain ? edit_customer.value.address__plain : edit_customer.value.address;
    edit_customer.value.notes = edit_customer.value.notes__plain ? edit_customer.value.notes__plain : edit_customer.value.notes;

    if (!customer_modal) {
        customer_modal = new Modal($targetEl, modal_options);
    }

    modal = customer_modal;
    modal.show();
};

const openMenuModal = (menu) => {
    const $targetEl = document.getElementById("menu-modal");

    edit_menu.value = JSON.parse(JSON.stringify(menu_defaults));
    _.merge(edit_menu.value, menu);

    if (!menu_modal) {
        menu_modal = new Modal($targetEl, modal_options);
    }

    modal = menu_modal;
    modal.show();
};

const openOrderModal = (order) => {
    const $targetEl = document.getElementById("order-modal");

    edit_order.value = JSON.parse(JSON.stringify(order_defaults));
    _.merge(edit_order.value, order);

    if (!order_modal) {
        order_modal = new Modal($targetEl, modal_options);
    }

    modal = order_modal;
    modal.show();
};

const openPaymentModal = (payment) => {
    const $targetEl = document.getElementById("payment-modal");

    edit_payment.value = JSON.parse(JSON.stringify(payment_defaults));
    _.merge(edit_payment.value, payment);

    if (!payment_modal) {
        payment_modal = new Modal($targetEl, modal_options);
    }

    modal = payment_modal;
    modal.show();
};

const closeModal = () => {
    if (modal) {
        modal.hide();
    }
};
</script>
