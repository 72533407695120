<template>
    <div class="mb-4">
        <label :for="uuid" class="block uppercase tracking-wide text-xs font-bold mb-2" v-if="label">{{ label }}</label>
        <input
            :id="uuid"
            :type="inputType"
            v-model="inputComputed"
            :placeholder="placeholder"
            class="apperance-none block w-full bg-white text-black border py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-purple focus:ring-0 transition-colors duration-300 placeholder-gray"
            :class="error !== '' ? 'border-red-500' : 'border-gray'"
            :min="min"
            :max="max"
            :step="step"
        />
        <div v-if="error" class="text-red-500 text-sm text-right px-1">{{ error }}</div>
    </div>
</template>

<script setup>
import { onBeforeMount, defineProps, defineEmits, toRefs, computed } from "vue";

const emit = defineEmits(["update:input"]);
const props = defineProps({
    label: [String, Number],
    input: [String, Number],
    placeholder: { type: String, default: "" },
    inputType: String,
    min: Number,
    max: Number,
    step: Number,
    error: String,
});

const { label, input, placeholder, min, max, step, error } = toRefs(props);

const inputComputed = computed({
    get: () => input.value,
    set: (val) => emit("update:input", val),
});

let uuid = 0;
onBeforeMount(() => {
    uuid = Math.random().toString().substring(2);
});
</script>

<style></style>
