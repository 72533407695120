<template>
    <div
        id="payment-modal"
        tabindex="-1"
        aria-hidden="true"
        class="fixed top-0 left-0 right-0 z-50 hidden w-full py-16 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full"
    >
        <div v-if="payment" class="relative w-full h-full max-w-md md:h-auto">
            <!-- Modal content -->
            <div class="relative bg-white shadow">
                <button
                    type="button"
                    class="absolute top-3 right-2.5 text-gray bg-transparent hover:bg-gray-light hover:text-gray-dark text-sm p-1.5 ml-auto inline-flex items-center"
                    @click="emitter.emit('close-modal')"
                >
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
                <div class="px-6 py-6 lg:px-8">
                    <div class="mb-4">Zahlung</div>

                    <div class="mb-4">
                        <select-input
                            v-show="!payment.options.fixedcustomer"
                            label="Kunde"
                            v-model:input="payment.customer.id"
                            :error="errors.customer_id ? errors.customer_id[0] : ''"
                        >
                            <option v-for="(customer, index) in customers" :key="index" :value="customer.id">
                                {{ customer.name }}, {{ customer.address }}
                            </option>
                        </select-input>
                        <select-input
                            label="Zahlungsart"
                            v-model:input="payment.payment_method_id"
                            :error="errors.payment_method_id ? errors.payment_method_id[0] : ''"
                            @change="
                                () => {
                                    payment.payment_method = payment_methods.find((payment_method) => payment_method.id === payment.payment_method_id);
                                    errors.payment_method_id = '';
                                }
                            "
                        >
                            <option v-for="(payment_method, index) in payment_methods" :key="index" :value="payment_method.id">
                                {{ payment_method.name }}
                            </option>
                        </select-input>
                        <text-input
                            class="basis-32"
                            label="Betrag"
                            inputType="number"
                            :min="0.01"
                            :step="0.01"
                            v-model:input="payment.amount"
                            :error="errors.amount ? errors.amount[0] : ''"
                        />
                    </div>
                    <button
                        class="font-montserrat text-xs block w-full bg-purple-dark hover:bg-black text-white rounded-sm py-3 transition-colors duration-300 uppercase tracking-widest font-bold"
                        type="submit"
                        @click="savePayment"
                    >
                        <font-awesome-icon icon="fa-solid fa-user-edit" />
                        &nbsp;Speichern
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, inject, onMounted, ref, toRefs } from "vue";
import { useToast } from "vue-toast-notification";

import TextInput from "@/components/global/TextInput.vue";
import SelectInput from "@/components/global/SelectInput.vue";
import axios from "axios";

const props = defineProps({
    payment: Object,
});
const { payment } = toRefs(props);

const emitter = inject("emitter");

const errors = ref([]);
const customers = ref([]);
const payment_methods = ref([]);

const $toast = useToast();

onMounted(async () => {
    customers.value = (await axios.get("customers/all")).data;
    payment_methods.value = (await axios.get("payment_methods")).data;
});

const savePayment = async () => {
    errors.value = [];
    let saved_payment = {};

    try {
        if (payment.value.id) {
            let data = {
                payment_id: payment.value.id,
                customer_id: payment.value.customer.id,
                payment_method_id: payment.value.payment_method.id,
                amount: payment.value.amount,
            };
            saved_payment = (await axios.post("payment/" + payment.value.id + "?_method=PUT", data)).data.payment;
        } else {
            let data = {
                customer_id: payment.value.customer.id,
                payment_method_id: payment.value.payment_method.id,
                amount: payment.value.amount,
            };
            saved_payment = (await axios.post("payment", data)).data.payment;
        }
        $toast.success("Zahlung gespeichert!");
        emitter.emit("payment-saved", saved_payment);
        emitter.emit("close-modal");
    } catch (error) {
        let error_string = "";
        if (error.response.data.error) {
            error_string = error.response.data.error;
        } else if (error.response.data.errors) {
            errors.value = error.response.data.errors;
            return;
        } else {
            error_string = error;
        }
        $toast.error("Fehler: " + error_string + "<br>Zahlung nicht gespeichert!");
    }

    emitter.emit("close-modal", true);
};
</script>

<style>
.dp__input {
    border: none;
}
</style>
