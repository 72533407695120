<template>
    <button
        class="font-montserrat text-xs block w-full bg-green-500 hover:bg-black text-white py-3 mb-4 transition-colors duration-300 uppercase tracking-widest font-bold"
        type="submit"
        @click="emitter.emit('open-order-modal', order)"
    >
        <font-awesome-icon icon="fa-solid fa-file-circle-plus" />
        &nbsp;
        <slot>Bestellung aufnehmen</slot>
    </button>
</template>

<script setup>
import { defineProps, inject, toRefs } from "vue";

const props = defineProps({ order: Object });

const { order } = toRefs(props);

const emitter = inject("emitter");
</script>

<style></style>
