<template>
    <searchable-customer-list />
</template>

<script setup>
import { useTitleStore } from "@/store/title-store";

import SearchableCustomerList from "@/components/partials/customers/SearchableCustomerList.vue";

const titleStore = useTitleStore();
titleStore.setTitle("Kunden");
</script>
