<template>
    <nav class="mt-1" v-if="pagination_data.last_page > 1">
        <ul class="inline-flex items-center -space-x-px">
            <li>
                <div
                    @click="emit('prev-page')"
                    class="block px-3 py-2 ml-0 border border-gray-300 leading-5 text-sm"
                    :disabled="pagination_data.prev_page_url === null"
                    :class="
                        pagination_data.prev_page_url === null
                            ? 'text-gray-light bg-gray-lighter cursor-default disabled'
                            : 'text-gray bg-white hover:text-gray-dark hover:bg-gray-lighter cursor-pointer'
                    "
                >
                    <span class="sr-only">Previous</span>
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                </div>
            </li>
            <li>
                <div class="px-3 py-2 text-gray-500 bg-white border border-gray-300 cursor-default leading-5">
                    Seite {{ pagination_data.current_page }} / {{ pagination_data.last_page }}
                </div>
            </li>
            <li>
                <div
                    @click="emit('next-page')"
                    class="block px-3 py-2 border border-gray-300 leading-5"
                    :disabled="pagination_data.next_page_url === null"
                    :class="
                        pagination_data.next_page_url === null
                            ? 'text-gray-light bg-gray-lighter cursor-default disabled'
                            : 'text-gray bg-white hover:text-gray-dark hover:bg-gray-lighter cursor-pointer'
                    "
                >
                    <span class="sr-only">Next</span>
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                </div>
            </li>
        </ul>
    </nav>
</template>

<script setup>
import { defineEmits, defineProps, toRefs } from "vue";

const props = defineProps({ pagination_data: Object });
const { pagination_data } = toRefs(props);

const emit = defineEmits(["next-page", "prev-page"]);
</script>
