import axios from "axios";

const user = JSON.parse(window.localStorage.getItem("user"));

axios.defaults.baseURL = process.env.VUE_APP_API;

axios.interceptors.request.use(
    (config) => {
        if (user && user.token) {
            config.headers.Authorization = `Bearer ${user.token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const status = error.response ? error.response.status : null;
        if (status === 401) {
            localStorage.removeItem("user");
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
);
