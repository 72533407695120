<template>
    <div class="flex items-center gap-4 flex-wrap m-8 mb-4 justify-around">
        <router-link
            :to="'/daytour/' + route.params.tour + '/' + prevDay.format('YYYY-MM-DD') + (isAdmin ? '?admin=1' : '')"
            class="text-xl font-bold tracking-widest cursor-pointer mb-4 text-black"
        >
            &lt;
        </router-link>
        <div class="text-xl font-bold tracking-widest cursor-pointer mb-4 text-black text-center basis-1/2">
            <router-link
                :to="'/daytour/' + route.params.tour + '/' + dayjs().format('YYYY-MM-DD') + (isAdmin ? '?admin=1' : '')"
                class="border-b-4 whitespace-nowrap"
                :class="date.isSame(dayjs(), 'day') ? 'border-purple' : 'border-gray'"
            >
                <span v-if="date.isAfter(dayjs(), 'day')">&lt;&lt;</span>
                {{ date.locale("de").format("dd DD.MM.YYYY") }}
                <span v-if="date.isBefore(dayjs(), 'day')">&gt;&gt;</span>
            </router-link>
        </div>
        <router-link
            :to="'/daytour/' + route.params.tour + '/' + nextDay.format('YYYY-MM-DD') + (isAdmin ? '?admin=1' : '')"
            class="text-xl font-bold tracking-widest cursor-pointer mb-4 text-black"
        >
            &gt;
        </router-link>
    </div>

    <Skeletor v-if="!meals" height="100" class="mb-4" />
    <div v-else class="mx-4 mb-4">
        <div v-for="(meal, index) in meals" :key="index" class="flex gap-2">
            <div class="shrink-0">
                {{ meal.count }}
                <font-awesome-icon icon="fa-solid fa-xmark" class="text-sm" />
            </div>
            <div>
                {{ meal.name }}
            </div>
        </div>
    </div>

    <Skeletor v-if="!daytour && !meals" height="60vh" />
    <template v-else-if="daytour">
        <div class="text-center">
            <template v-if="date.isSame(dayjs(), 'day')">
                {{ daytour.daytour_orders_by_status.done.length + daytour.daytour_orders_by_status.cancelled.length }} /
                {{ daytour.daytour_orders.length }} Stopps erledigt
            </template>
            <template v-else>{{ daytour.daytour_orders.length }} Stopps</template>
        </div>

        <template v-if="isAdmin">
            <admin-daytour-table v-if="daytour" :daytour_orders="daytour.daytour_orders_by_status" :isBeforeToday="date.isBefore(dayjs(), 'day')" />
        </template>
        <template v-else>
            <daytour-table
                v-if="daytour"
                :daytour_orders="daytour.daytour_orders_by_status.open"
                :isToday="date.isSame(dayjs(), 'day')"
                :isBeforeToday="date.isBefore(dayjs(), 'day')"
                :totalOrderCount="daytour.totalOrderCount"
            />
            <div v-else-if="errors.daytour && !errors.meals" class="text-center">
                {{ errors.daytour }}
            </div>

            <done-daytour-table v-if="daytour && daytour.daytour_orders_by_status.done.length" :daytour_orders="daytour.daytour_orders_by_status.done" />
            <done-daytour-table
                v-if="daytour && daytour.daytour_orders_by_status.cancelled.length"
                :daytour_orders="daytour.daytour_orders_by_status.cancelled"
            />

            <!-- 
            <order-modal @close-modal="closeModal" :order="edit_order" :fixedcustomer="true" />
            <payment-modal @close-modal="closeModal" :payment="edit_payment" :fixedcustomer="true" /> -->
        </template>
    </template>
</template>

<script setup>
import { computed, inject, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useTitleStore } from "@/store/title-store";

import AdminDaytourTable from "@/components/partials/daytour/AdminDaytourTable.vue";
import DaytourTable from "@/components/partials/daytour/DaytourTable.vue";
import DoneDaytourTable from "@/components/partials/daytour/DoneDaytourTable.vue";

import dayjs from "dayjs";
import axios from "axios";
require("dayjs/locale/de");

const route = useRoute();
const titleStore = useTitleStore();
const date = ref(dayjs());

const emitter = inject("emitter");

const isAdmin = ref(false);
const daytour = ref(null);
const meals = ref(null);

const errors = ref([]);

onMounted(async () => {
    if (route.params.date) date.value = dayjs(route.params.date);
    isAdmin.value = route.query.admin;

    getDaytour();
});

const getDaytour = () => {
    errors.value = [];

    axios
        .get("menu_by_date/" + date.value.format("YYYY-MM-DD") + "/" + route.params.tour)
        .then((response) => {
            meals.value = response.data.menu.meals;
        })
        .catch((error) => {
            if (error.response.status === 404) {
                errors.value["meals"] = error.response.data.message;
                meals.value = [];
            } else {
                console.log(error);
            }
        });

    axios
        .get("daytour/" + route.params.tour + "/" + date.value.format("YYYY-MM-DD"))
        .then((response) => {
            daytour.value = response.data;
            titleStore.setTitle("Tour - " + daytour.value.tour.name);

            if (daytour.value && daytour.value.daytour_orders && daytour.value.daytour_orders.length) {
                daytour.value.totalOrderCount = daytour.value.daytour_orders.length;
            }
        })
        .catch((error) => {
            if (error.response.data.tour && error.response.data.tour.name) {
                titleStore.setTitle("Tour - " + error.response.data.tour.name);
            } else {
                titleStore.setTitle("Tour");
            }

            if (error.response.status === 404) {
                errors.value["daytour"] = error.response.data.message;
            } else {
                console.log(error);
            }
        });
};

const stopDone = (tourstop, status = "done") => {
    axios.post("daytour_order/" + status + "/" + tourstop.id + "?_method=PUT").then(() => {
        daytour.value.daytour_orders_by_status[status].unshift(tourstop);

        let index = daytour.value.daytour_orders_by_status.open.indexOf(tourstop);
        if (index > -1) {
            daytour.value.daytour_orders_by_status.open.splice(index, 1);
        }

        tourstop.isOpened = false;
        tourstop.status = status;
    });
};

emitter.on("stop-done", (tourstop) => {
    stopDone(tourstop);
});

emitter.on("stop-cancelled", (tourstop) => {
    stopDone(tourstop, "cancelled");
});

emitter.on("stop-restore", (tourstop) => {
    axios.post("daytour_order/open/" + tourstop.id + "?_method=PUT").then(() => {
        let insert_before = -1;
        daytour.value.daytour_orders_by_status.open.forEach((daytour_order, index) => {
            if (insert_before == -1) {
                if (tourstop.sort_order <= daytour_order.sort_order) {
                    insert_before = index;
                }
            }
        });

        daytour.value.daytour_orders_by_status.open.splice(insert_before, 0, tourstop);

        let index = daytour.value.daytour_orders_by_status[tourstop.status].indexOf(tourstop);
        if (index > -1) {
            daytour.value.daytour_orders_by_status[tourstop.status].splice(index, 1);
        }

        tourstop.isOpened = false;
        tourstop.status = "open";
    });
});

const prevDay = computed({
    get: () => date.value.subtract(1, "days"),
});
const nextDay = computed({
    get: () => date.value.add(1, "days"),
});
</script>
