<template>
    <div class="flex items-center gap-4 flex-wrap m-8 mb-4 justify-around">
        <router-link :to="'/analysis/payments/' + prevDay.format('YYYY-MM-DD')" class="text-xl font-bold tracking-widest cursor-pointer mb-4 text-black">
            &lt;
        </router-link>
        <div class="text-xl font-bold tracking-widest cursor-pointer mb-4 text-black text-center basis-1/2">
            <router-link
                :to="'/analysis/payments/' + dayjs().format('YYYY-MM-DD')"
                class="border-b-4 whitespace-nowrap"
                :class="date.isSame(dayjs(), 'day') ? 'border-purple' : 'border-gray'"
            >
                <span v-if="date.isAfter(dayjs(), 'day')">&lt;&lt;</span>
                {{ date.locale("de").format("dd DD.MM.YYYY") }}
                <span v-if="date.isBefore(dayjs(), 'day')">&gt;&gt;</span>
            </router-link>
        </div>
        <router-link
            v-if="date.isBefore(dayjs(), 'day')"
            :to="'/analysis/payments/' + nextDay.format('YYYY-MM-DD')"
            class="text-xl font-bold tracking-widest cursor-pointer mb-4 text-black"
        >
            &gt;
        </router-link>
        <div v-else class="text-xl font-bold tracking-widest mb-4 text-gray-light">&gt;</div>
    </div>

    <Skeletor v-if="!payments" height="50vh" />
    <table v-else class="w-full">
        <tr class="text-left uppercase text-sm text-white bg-gray-dark">
            <th class="p-4">Kunde</th>
            <th class="p-4">Betrag</th>
        </tr>
        <template v-for="(payment, index) in payments" :key="index">
            <tr :class="index % 2 == 0 ? 'bg-gray-lighter' : 'bg-white'" class="h-20">
                <td>
                    <div class="p-4" v-if="payment.customer">
                        {{ payment.customer.name }}
                        <div class="text-sm">{{ payment.customer.address }}</div>
                    </div>
                    <div class="p-4" v-else>
                        <i>- gelöscht -</i>
                    </div>
                </td>
                <td>
                    <div class="p-4">
                        {{ currency.format(payment.amount) }}
                        <div class="text-sm">{{ payment.payment_method.name }}</div>
                    </div>
                </td>
            </tr>
        </template>
        <tr class="bg-gray-dark text-white h-20">
            <td><div class="p-4">Gesamt</div></td>
            <td>
                <div class="p-4">{{ currency.format(total) }}</div>
            </td>
        </tr>
    </table>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useTitleStore } from "@/store/title-store";
import { useRoute } from "vue-router";

import dayjs from "dayjs";
import axios from "axios";
require("dayjs/locale/de");

const route = useRoute();
const titleStore = useTitleStore();
const date = ref(dayjs());
const total = ref(0);

const errors = ref([]);
const payments = ref(null);

const currency = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
});

const getPayments = () => {
    errors.value = [];

    axios
        .get("payments/" + date.value.format("YYYY-MM-DD"))
        .then((response) => {
            payments.value = response.data.payments;

            total.value = 0;
            payments.value.forEach((payment) => {
                total.value += payment.amount;
            });
        })
        .catch((error) => {
            console.log(error);
            if (error.response.status === 404) {
                errors.value["payments"] = error.response.data.message;
                payments.value = [];
            } else {
                console.log(error);
            }
        });
};

onMounted(async () => {
    titleStore.setTitle("Auswertung - Zahlungen");

    if (route.params.date) date.value = dayjs(route.params.date);

    getPayments();
});

const prevDay = computed({
    get: () => date.value.subtract(1, "days"),
});
const nextDay = computed({
    get: () => date.value.add(1, "days"),
});
</script>
