import { defineStore } from "pinia";

export const useTitleStore = defineStore("title", {
    state: () => ({
        title: "",
    }),

    actions: {
        async setTitle(title) {
            this.title = title;
        },
    },
});
