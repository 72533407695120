<template>
    <div class="max-w-xs mx-auto p-8">
        <div class="mb-4">
            <text-input
                label="Benutzername"
                placeholder="Benutzername"
                v-model:input="username"
                inputType="text"
                :error="errors.username ? errors.username[0] : ''"
                @keyup.enter="login"
            />
        </div>

        <div class="mb-4">
            <text-input
                label="Passwort"
                placeholder="Passwort"
                v-model:input="password"
                inputType="password"
                :error="errors.password ? errors.password[0] : ''"
                @keyup.enter="login"
            />
        </div>

        <button
            class="font-montserrat text-xs block w-full bg-purple hover:bg-black text-white rounded-sm py-3 transition-colors duration-300 uppercase tracking-widest font-bold"
            type="submit"
            @click="login"
        >
            <font-awesome-icon icon="fa-regular fa-share-square" />
            &nbsp;Einloggen
        </button>

        <div v-if="false" class="text-gray text-sm text-right">
            Passwort vergessen? Hier
            <a href="#" class="font-bold">zurücksetzen</a>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { useUserStore } from "../store/user-store";
import { useToast } from "vue-toast-notification";
import { useTitleStore } from "@/store/title-store";
import axios from "axios";

import TextInput from "@/components/global/TextInput.vue";

const userStore = useUserStore();
const titleStore = useTitleStore();
const $toast = useToast();

let errors = ref([]);
let username = ref(null);
let password = ref(null);

titleStore.setTitle("Login");

const login = async () => {
    errors.value = [];
    try {
        let result = await axios.post("login", {
            username: username.value,
            password: password.value,
        });
        $toast.success("Erfolgreich als " + username.value + " eingeloggt.");
        userStore.setUserDetails(result);
        window.location.reload();
    } catch (error) {
        let error_string = "";
        if (error.response.data.error) {
            error_string = error.response.data.error;
        } else if (error.response.data.errors) {
            errors.value = error.response.data.errors;
            return;
        } else {
            error_string = error;
        }
        $toast.error(error_string);
    }
};
</script>
