<template>
    <template v-if="orders">
        <template v-if="tour_orders[-1]">
            <h2 class="text-center p-4 text-white bg-purple">nicht zugeordnet ({{ tour_orders[-1].length }})</h2>
            <draggable
                class="list-group w-full mb-8"
                tag="table"
                :component-data="{
                    type: 'transition-group',
                }"
                :list="tour_orders[-1]"
                group="orders"
                item-key="order"
                handle=".handle"
                :animation="250"
                @start="isDragging = true"
                @change="orderMoved"
                @end="isDragging = false"
            >
                <template #item="{ element }">
                    <tbody>
                        <orders-table-row :sortable="true" :order="element" :is-dragging="isDragging" :tours="tours" />
                    </tbody>
                </template>
            </draggable>
        </template>

        <Skeletor v-if="!tours" height="60vh" />
        <template v-for="tour in tours" :key="tour.id">
            <template v-if="tour_orders[tour.id]">
                <h2 class="text-center p-4 text-white bg-purple">{{ tour.name }} ({{ tour_orders[tour.id].length }})</h2>
                <draggable
                    class="list-group w-full mb-8"
                    tag="table"
                    :component-data="{
                        type: 'transition-group',
                    }"
                    :list="tour_orders[tour.id]"
                    group="orders"
                    item-key="order"
                    handle=".handle"
                    :animation="250"
                    @start="isDragging = true"
                    @change="orderMoved"
                    @end="isDragging = false"
                >
                    <template #item="{ element }">
                        <tbody>
                            <orders-table-row :sortable="true" :order="element" :is-dragging="isDragging" :tours="tours" />
                        </tbody>
                    </template>
                </draggable>
            </template>
        </template>
    </template>
    <div v-else class="text-center m-4">Für diesen Tag wurden keine Bestellungen gefunden.</div>
</template>

<script setup>
import { toRefs, defineProps, onMounted, ref, watch, inject } from "vue";
import draggable from "vuedraggable";

import OrdersTableRow from "./OrdersTableRow.vue";

const props = defineProps({
    orders: Object,
    tours: Object,
});

const { orders, tours } = toRefs(props);

const tour_orders = ref([]);

const emitter = inject("emitter");

onMounted(() => {
    tour_orders.value[-1] = orders.value.filter((order) => order.tour_id === null);
});

watch(tours, () => {
    tours.value.forEach((tour) => {
        tour_orders.value[tour.id] = orders.value.filter((order) => order.tour_id === tour.id);
    });
});
emitter.on("order-moved", () => {
    tour_orders.value[-1] = orders.value.filter((order) => order.tour_id === null);
    tours.value.forEach((tour) => {
        tour_orders.value[tour.id] = orders.value.filter((order) => order.tour_id === tour.id);
    });
});

const isDragging = ref(false);

const orderMoved = (e) => {
    // emitter.emit("order-moved");
    console.log(e);
};
</script>

<style></style>
