<template>
    <div
        id="order-modal"
        tabindex="-1"
        aria-hidden="true"
        class="fixed top-0 left-0 right-0 z-50 hidden w-full py-16 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full"
    >
        <div v-if="order" class="relative w-full h-full max-w-md md:h-auto">
            <!-- Modal content -->
            <div class="relative bg-white shadow">
                <button
                    type="button"
                    class="absolute top-3 right-2.5 text-gray bg-transparent hover:bg-gray-light hover:text-gray-dark text-sm p-1.5 ml-auto inline-flex items-center"
                    @click="closeModal"
                >
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
                <div class="px-6 py-6 lg:px-8">
                    <div class="mb-4">
                        Bestellung
                        <template v-if="picked_date">für {{ dayjs(picked_date).locale("de").format("dd, DD.MM.YYYY") }}</template>
                    </div>

                    <div v-show="!order.options || !order.options.fixeddate" class="mb-4">
                        <label for="date-picker" class="block uppercase tracking-wide text-xs font-bold mb-2">Datum</label>
                        <Skeletor v-if="!dates_with_menu" />
                        <vue-date-picker
                            v-else
                            id="date-picker"
                            v-model="picked_date"
                            @update:model-value="loadMenuByDate"
                            :enable-time-picker="false"
                            :min-date="dayjs().format()"
                            :allowed-dates="dates_with_menu"
                            :auto-apply="true"
                            :prevent-min-max-navigation="true"
                            :six-weeks="true"
                            :clearable="false"
                            locale="de-DE"
                            :format-locale="de"
                            cancelText="Abbrechen"
                            selectText="Auswählen"
                            format="EEEEEE., dd.MM.yyyy"
                            class="apperance-none block w-full bg-white text-black border border-gray py-1 leading-tight focus:outline-none focus:bg-white focus:border-purple focus:ring-0 transition-colors duration-300 placeholder-gray"
                        />
                        <div v-if="errors.date" class="text-red-500 text-sm text-right px-1">{{ errors.date[0] }}</div>
                    </div>

                    <div class="mb-4">
                        <select-input
                            v-show="!order.options || !order.options.fixedcustomer"
                            label="Kunde"
                            v-model:input="order.customer.id"
                            :error="errors.customer_id ? errors.customer_id[0] : ''"
                            @change="
                                () => {
                                    order.customer = customers.find((customer) => customer.id === order.customer.id);
                                    errors.customer_id = '';
                                }
                            "
                        >
                            <option v-for="(customer, index) in customers" :key="index" :value="customer.id">
                                {{ customer.name }}, {{ customer.address }}
                            </option>
                        </select-input>
                        <Skeletor v-if="!dates_with_menu || !weekmenu.meals" height="284" />
                        <div v-else-if="!weekmenu || !weekmenu.meals || weekmenu.meals.length === 0" class="text-red-500 text-center px-1">
                            Für dieses Datum sind keine Gerichte hinterlegt.
                        </div>
                        <template v-else>
                            <div v-for="(ordermeal, index) in order.ordermeals" :key="index">
                                <div class="flex gap-2 items-center">
                                    <div class="flex grow">
                                        <select-input
                                            v-if="weekmenu"
                                            class="grow"
                                            label="Gericht"
                                            v-model:input="ordermeal.meal_id"
                                            :error="errors['ordermeals.' + index + '.meal.id'] ? errors['ordermeals.' + index + '.meal.id'][0] : ''"
                                            @change="
                                                () => {
                                                    ordermeal.meal = weekmenu.meals.find((meal) => meal.id === ordermeal.meal_id);
                                                    errors['ordermeals.' + index + '.meal.id'] = '';
                                                }
                                            "
                                        >
                                            <option v-for="(weekmeal, index) in weekmenu.meals" :key="index" :value="weekmeal.id">
                                                {{ weekmeal.name }}
                                                <template v-if="weekmeal.is_veggie">(veg)</template>
                                            </option>
                                        </select-input>
                                        <text-input
                                            class="basis-32"
                                            label="Menge"
                                            inputType="number"
                                            :min="1"
                                            v-model:input="ordermeal.quantity"
                                            :error="errors['ordermeals.' + index + '.quantity'] ? errors['ordermeals.' + index + '.quantity'][0] : ''"
                                        />
                                    </div>
                                    <font-awesome-icon
                                        :class="ordermeal.disabled ? 'text-gray-light' : 'text-red-700'"
                                        class="cursor-pointer"
                                        icon="fa-solid fa-trash-alt"
                                        @click="deleteOrderMeal(ordermeal)"
                                    />
                                </div>
                            </div>
                            <button
                                class="font-montserrat text-xs block w-full bg-purple hover:bg-black text-white rounded-sm py-3 mb-2 transition-colors duration-300 uppercase tracking-widest font-bold"
                                @click="addMeal"
                            >
                                <font-awesome-icon icon="fa-solid fa-plus" />
                                &nbsp;Gericht hinzufügen
                            </button>
                            <div v-if="errors.ordermeals" class="text-red-500 text-sm text-right px-1">{{ errors.ordermeals[0] }}</div>
                            <textarea-input label="Hinweise" placeholder="Hinweise" v-model:input="order.notes" :error="errors.notes ? errors.notes[0] : ''" />
                        </template>
                    </div>
                    <button
                        class="font-montserrat text-xs block w-full bg-purple-dark hover:bg-black text-white rounded-sm py-3 transition-colors duration-300 uppercase tracking-widest font-bold disabled:bg-gray-lighter disabled:text-gray-light"
                        type="submit"
                        @click="saveOrder"
                        :disabled="order.ordermeals.find((o) => o.meal.id === null)"
                    >
                        <font-awesome-icon icon="fa-solid fa-user-edit" />
                        &nbsp;Speichern
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, inject, onMounted, ref, toRefs, watch } from "vue";
import { useToast } from "vue-toast-notification";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import SelectInput from "@/components/global/SelectInput.vue";
import TextInput from "@/components/global/TextInput.vue";
import TextareaInput from "@/components/global/TextareaInput.vue";
import axios from "axios";
import dayjs from "dayjs";
import { de } from "date-fns/locale";
require("dayjs/locale/de");

const props = defineProps({
    order: Object,
});
const { order } = toRefs(props);

const emitter = inject("emitter");

const errors = ref([]);
const customers = ref([]);
const picked_date = ref(null);
const dates_with_menu = ref(null);
const weekmenu = ref({});
const ordermeals_to_delete = ref([]);

const $toast = useToast();

onMounted(async () => {
    customers.value = (await axios.get("customers/all")).data;
    dates_with_menu.value = (await axios.get("dates_with_menu")).data.dates_with_menu;
});

watch(order, function () {
    if (order.value.date) picked_date.value = order.value.date;
    else if (!picked_date.value) picked_date.value = dayjs();
    ordermeals_to_delete.value = [];
    loadMenuByDate();
});

const addMeal = () => {
    if (!order.value.ordermeals) order.value.ordermeals = [];
    order.value.ordermeals.push({ meal: { id: null }, quantity: 1 });
};
const deleteOrderMeal = (ordermeal_to_delete) => {
    if (ordermeal_to_delete.id && ordermeals_to_delete.value.findIndex((otd) => otd.id === ordermeal_to_delete.id) === -1) {
        ordermeals_to_delete.value.push(ordermeal_to_delete);
    }
    order.value.ordermeals = order.value.ordermeals.filter((ordermeal) => ordermeal !== ordermeal_to_delete);

    if (!order.value.ordermeals.length) {
        addMeal();
    }
};

const saveOrder = async () => {
    errors.value = [];
    let saved_order = {};

    try {
        if (order.value.id) {
            let data = {
                order_id: order.value.id,
                customer_id: order.value.customer.id,
                ordermeals: order.value.ordermeals,
                ordermeals_to_delete: ordermeals_to_delete.value,
                notes: order.value.notes,
                date: dayjs(picked_date.value).format("YYYY-MM-DD"),
            };
            saved_order = (await axios.post("order/" + order.value.id + "?_method=PUT", data)).data.order;
        } else {
            let data = {
                customer_id: order.value.customer.id,
                ordermeals: order.value.ordermeals,
                notes: order.value.notes,
                date: dayjs(picked_date.value).format("YYYY-MM-DD"),
            };
            saved_order = (await axios.post("order", data)).data.order;
        }

        $toast.success("Bestellung gespeichert!");
        emitter.emit("order-saved", saved_order);
        closeModal();
    } catch (error) {
        let error_string = "";
        if (error.response.data.error) {
            error_string = error.response.data.error;
        } else if (error.response.data.errors) {
            errors.value = error.response.data.errors;
            return;
        } else {
            error_string = error;
        }
        $toast.error("Fehler: " + error_string + "<br>Bestellung nicht gespeichert!");
    }
};

const loadMenuByDate = async () => {
    weekmenu.value = {};

    try {
        weekmenu.value = (await axios.get("menu_by_date/" + dayjs(picked_date.value).format("YYYY-MM-DD"))).data.menu;
    } catch (error) {
        weekmenu.value.meals = [];
    }

    order.value.ordermeals.forEach((ordermeal) => {
        if (
            !weekmenu.value.meals.filter((meal) => {
                return meal.id === ordermeal.meal.id;
            }).length
        ) {
            deleteOrderMeal(ordermeal);
        }
    });
};

const closeModal = () => {
    ordermeals_to_delete.value = [];
    emitter.emit("close-modal");
};

emitter.on("menu-saved", async () => {
    dates_with_menu.value = (await axios.get("dates_with_menu")).data.dates_with_menu;
});
</script>

<style>
.dp__input {
    border: none;
}
.dp__cell_inner {
    font-weight: bolder;
}
.dp__cell_disabled {
    font-weight: normal;
}
</style>
