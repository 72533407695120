<template>
    <div class="mb-4">
        <label :for="uuid" class="block uppercase tracking-wide text-xs font-bold mb-2" :class="disabled ? 'text-gray-light' : ''">
            {{ label }}
        </label>
        <textarea
            :id="uuid"
            v-model="inputComputed"
            :placeholder="placeholder"
            class="apperance-none block w-full border py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-purple focus:ring-0 transition-colors duration-300 placeholder-gray"
            :class="disabled ? 'text-gray-light bg-gray-lighter border-gray-light' : 'text-black bg-white border-gray'"
            :rows="rows"
            @input="adjustTextareaHeight"
            ref="textarea"
            :disabled="disabled"
        ></textarea>
        <span v-if="error" class="text-red-500">{{ error }}</span>
    </div>
</template>

<script setup>
import { onBeforeMount, onMounted, onUpdated, defineProps, defineEmits, toRefs, computed, ref } from "vue";

const emit = defineEmits(["update:input"]);
const props = defineProps({
    label: String,
    input: String,
    placeholder: { type: String, default: "" },
    rows: { type: Number, default: 5 },
    autoheight: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    error: String,
});

const { label, input, placeholder, rows, autoheight, error } = toRefs(props);

const inputComputed = computed({
    get: () => input.value,
    set: (val) => emit("update:input", val),
});

const textarea = ref({});

const adjustTextareaHeight = () => {
    if (autoheight.value) {
        textarea.value.style.height = "auto";
        textarea.value.style.height = parseInt(textarea.value.scrollHeight) + 2 + "px";
    }
};

let uuid = 0;
onBeforeMount(() => {
    uuid = Math.random().toString().substring(2);
});

onMounted(() => {
    adjustTextareaHeight();
});

onUpdated(() => {
    adjustTextareaHeight();
});
</script>

<style></style>
