<template>
    <div class="flex items-center gap-4 flex-wrap m-8">
        <div class="text-xl font-bold tracking-widest cursor-pointer" id="customer-tab-data">Kundendaten</div>
        <div class="text-xl font-bold tracking-widest cursor-pointer" id="customer-tab-transactions">Transaktionen</div>
    </div>
    <Skeletor v-if="!customer" height="60vh" />
    <template v-else>
        <div id="customer-content-data">
            <customer-details-table :customer="customer" :show-tour="true" />
            <div class="mx-4 py-4">
                <button
                    class="font-montserrat text-xs block w-full bg-purple hover:bg-black text-white py-3 transition-colors duration-300 uppercase tracking-widest font-bold"
                    type="submit"
                    @click="emitter.emit('open-customer-modal', customer)"
                >
                    <font-awesome-icon icon="fa-solid fa-pencil-alt" />
                    &nbsp;Kunde bearbeiten
                </button>
            </div>
            <div class="mx-4 pb-4">
                <button
                    class="font-montserrat text-xs block w-full bg-red-600 hover:bg-black text-white py-3 transition-colors duration-300 uppercase tracking-widest font-bold"
                    type="submit"
                    @click="deleteCustomer(customer)"
                >
                    <font-awesome-icon icon="fa-solid fa-trash-alt" />
                    &nbsp;Kunde löschen
                </button>
            </div>
        </div>
        <div id="customer-content-transactions">
            <table class="w-full">
                <tr class="text-left uppercase text-sm text-white bg-gray-dark">
                    <th class="p-4">Datum</th>
                    <th class="p-4">Art</th>
                    <th class="p-4">Aktionen</th>
                </tr>
                <template v-for="(transactions_date, index) in Object.fromEntries(Object.entries(transactions).slice(0, ll_transaction_count))" :key="index">
                    <template v-for="(transaction, index2) in transactions_date" :key="index2">
                        <template v-if="transaction">
                            <tr :class="index % 2 == 0 ? 'bg-gray-lighter' : 'bg-white'" class="h-20">
                                <td>
                                    <div class="p-4">{{ new Intl.DateTimeFormat("de-DE").format(Date.parse(transaction.data.created_at)) }}</div>
                                </td>
                                <td>
                                    <div class="p-4" v-if="transaction.type === 'order'">
                                        Bestellung ({{
                                            currency.format(
                                                transaction.data.ordermeals
                                                    .map((ordermeal) => ordermeal.quantity * ordermeal.meal.price)
                                                    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                                            )
                                        }})
                                    </div>
                                    <div class="p-4" v-else-if="transaction.type === 'payment'">
                                        Zahlung ({{ currency.format(transaction.data.amount) }})
                                        <div class="text-sm">{{ transaction.data.payment_method.name }}</div>
                                    </div>
                                </td>
                                <td>
                                    <div
                                        class="p-4 border-l border-gray-light text-gray-dark flex justify-around items-center gap-4"
                                        v-if="transaction.type === 'order'"
                                    >
                                        <div class="cursor-pointer" @click="transaction.isOpened = !transaction.isOpened">
                                            <font-awesome-icon
                                                icon="fa-solid fa-angle-down"
                                                class="transition-transform duration-300"
                                                :class="transaction.isOpened ? '-rotate-135' : ''"
                                            />
                                        </div>
                                        <round-button
                                            v-if="
                                                (transaction.type === 'payment') ||
                                                (transaction.data.date && dayjs(Date.parse(transaction.data.date)) >= dayjs().startOf('day'))
                                            "
                                            @click="transaction.isOpened && showTransactionModal(transaction)"
                                            :icon="'fa-solid fa-pencil-alt'"
                                            :disabled="!transaction.isOpened"
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr
                                v-if="transaction.type === 'order'"
                                :id="'order-details-' + index"
                                class="bg-gray-light"
                                :class="transaction.isOpened ? '' : 'hidden'"
                            >
                                <td colspan="3">
                                    <div class="flex flex-col">
                                        <div class="mx-4 py-4">
                                            <div>Bestelldatum: {{ dayjs(Date.parse(transaction.data.created_at)).locale("de").format("DD.MM.YYYY") }}</div>
                                            <div>Lieferdatum: {{ dayjs(Date.parse(transaction.data.date)).locale("de").format("DD.MM.YYYY") }}</div>
                                        </div>
                                        <div class="mx-4 py-4 flex">
                                            <div>
                                                <font-awesome-icon icon="fa-solid fa-map" class="mr-2" />
                                            </div>
                                            <div>
                                                <div v-for="(ordermeal, index) in transaction.data.ordermeals" :key="index" class="flex gap-2">
                                                    <div>{{ ordermeal.quantity }}x</div>
                                                    <div>{{ ordermeal.meal.name }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mx-4 py-4 border-t border-gray flex" v-if="transaction.data.notes">
                                            <div>
                                                <font-awesome-icon icon="fa-solid fa-circle-info" class="mr-2" />
                                            </div>
                                            <div v-if="transaction.data.notes" class="whitespace-pre-line">
                                                {{ transaction.data.notes }}
                                            </div>
                                        </div>

                                        <div class="mx-4 py-4" v-if="isAdmin">
                                            <button
                                                class="font-montserrat text-xs block w-full bg-red-600 hover:bg-black text-white py-3 transition-colors duration-300 uppercase tracking-widest font-bold"
                                                type="submit"
                                                @click="deleteOrder(transaction.data)"
                                            >
                                                <font-awesome-icon icon="fa-solid fa-trash-alt" />
                                                &nbsp;Bestellung löschen
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr
                                v-else-if="transaction.type === 'payment' && isAdmin"
                                :id="'payments-details-' + index"
                                class="bg-gray-light"
                                :class="transaction.isOpened ? '' : 'hidden'"
                            >
                                <td colspan="3">
                                    <div class="flex flex-col">
                                        <div class="mx-4 py-4">
                                            <button
                                                class="font-montserrat text-xs block w-full bg-red-600 hover:bg-black text-white py-3 transition-colors duration-300 uppercase tracking-widest font-bold"
                                                type="submit"
                                                @click="deletePayment(transaction.data)"
                                            >
                                                <font-awesome-icon icon="fa-solid fa-trash-alt" />
                                                &nbsp;Zahlung löschen
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </template>
                </template>
            </table>
            <div
                v-if="ll_transaction_count < Object.keys(transactions).length"
                @click="ll_transaction_count += 5"
                class="text-center cursor-pointer text-purple"
            >
                weitere anzeigen
            </div>

            <div class="mx-4 py-4">
                <new-order-button
                    :order="{
                        customer: customer,
                        options: { fixedcustomer: true },
                    }"
                >
                    Bestellung erfassen
                </new-order-button>
                <new-payment-button
                    :payment="{
                        customer: customer,
                        options: { fixedcustomer: true },
                    }"
                >
                    Zahlung erfassen
                </new-payment-button>
            </div>
        </div>
    </template>
</template>

<script setup>
import { ref, onMounted, inject, nextTick } from "vue";
import { Tabs } from "flowbite";
import { useRoute, useRouter } from "vue-router";
import { useTitleStore } from "@/store/title-store";
import { useToast } from "vue-toast-notification";

import RoundButton from "@/components/global/RoundButton.vue";
import NewOrderButton from "@/components/global/buttons/NewOrderButton.vue";
import NewPaymentButton from "@/components/global/buttons/NewPaymentButton.vue";
import CustomerDetailsTable from "@/components/partials/customers/CustomerDetailsTable.vue";

import axios from "axios";
import dayjs from "dayjs";
require("dayjs/locale/de");

const emitter = inject("emitter");

const currency = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
});

const showTransactionModal = (transaction) => {
    transaction.data.options = { fixedcustomer: true, fixeddate: true };
    emitter.emit("open-" + transaction.type + "-modal", transaction.data);
};

const route = useRoute();
const router = useRouter();
const titleStore = useTitleStore();

const customer = ref(null);
const orders = ref(null);
const payments = ref(null);
const transactions = ref({});
const ll_transaction_count = ref(5);
let tabs = null;

const $toast = useToast();

onMounted(() => {
    loadData();
});

emitter.on("order-saved", async (order) => {
    await updateOrder(order);
    prepareTransactions();
});

emitter.on("payment-saved", async (payment) => {
    await updatePayment(payment);
    prepareTransactions();
});

const loadData = () => {
    axios.get("customer/" + route.params.id).then((response) => {
        const data = response.data;

        customer.value = data.customer;
        orders.value = data.customer.orders;
        payments.value = data.customer.payments;
        titleStore.setTitle("Kunde - " + data.customer.name);

        prepareTransactions();

        if (tabs === null)
            nextTick(() => {
                let tabElements = [];
                tabElements.push({
                    id: "customer-data",
                    triggerEl: document.querySelector("#customer-tab-data"),
                    targetEl: document.querySelector("#customer-content-data"),
                });
                tabElements.push({
                    id: "customer-transactions",
                    triggerEl: document.querySelector("#customer-tab-transactions"),
                    targetEl: document.querySelector("#customer-content-transactions"),
                });

                const options = {
                    activeClasses: "text-black border-b-4 border-purple",
                    inactiveClasses: "text-gray-light",
                };
                tabs = new Tabs(tabElements, options);
            });
    });
};

const updateOrder = async (order) => {
    const index = orders.value.findIndex((o) => o.id === order.id);

    if (index !== -1) {
        orders.value.splice(index, 1, order);
    } else {
        order.created_at = dayjs();
        orders.value.push(order);
    }
};

const updatePayment = async (payment) => {
    const index = payments.value.findIndex((p) => p.id === payment.id);

    if (index !== -1) {
        payments.value.splice(index, 1, payment);
    } else {
        payment.created_at = dayjs();
        payments.value.push(payment);
    }
};

const prepareTransactions = () => {
    transactions.value = {};

    payments.value.forEach((payment) => {
        if (!transactions.value[payment.created_at]) transactions.value[payment.created_at] = [];
        payment.customer = { id: customer.value.id };
        transactions.value[payment.created_at].push({ type: "payment", data: payment, isOpened: payment.isOpened });
    });
    orders.value.forEach((order) => {
        if (!transactions.value[order.created_at]) transactions.value[order.created_at] = [];
        order.customer = { id: customer.value.id };
        transactions.value[order.created_at].push({ type: "order", data: order, isOpened: order.isOpened });
    });

    transactions.value = Object.keys(transactions.value)
        .sort((a, b) => {
            return Date.parse(b) - Date.parse(a);
        })
        .reduce((obj, key) => {
            obj[key] = transactions.value[key];
            return obj;
        }, {});
};

const deleteOrder = async (order) => {
    if (!confirm("Sind Sie sicher, dass sie diese Bestellung löschen möchten? Dies kann nicht rückgängig gemacht werden!")) return;

    try {
        await axios.delete("order/" + order.id);
        loadData();
        $toast.success("Bestellung gelöscht.");
    } catch (error) {
        let error_string = "";
        if (error.response.data.message) {
            error_string = error.response.data.message;
        } else {
            error_string = error;
        }
        $toast.error(error_string);
    }
};

const deletePayment = async (payment) => {
    if (!confirm("Sind Sie sicher, dass sie diese Zahlung löschen möchten? Dies kann nicht rückgängig gemacht werden!")) return;

    try {
        await axios.delete("payment/" + payment.id);
        loadData();
        $toast.success("Zahlung gelöscht.");
    } catch (error) {
        let error_string = "";
        if (error.response.data.message) {
            error_string = error.response.data.message;
        } else {
            error_string = error;
        }
        $toast.error(error_string);
    }
};

const deleteCustomer = async () => {
    if (!confirm("Sind Sie sicher, dass sie diesen Kunden löschen möchten? Dies kann nicht rückgängig gemacht werden!")) return;

    try {
        await axios.delete("customer/" + customer.value.id);
        $toast.success("Kunde gelöscht.");
        router.back();
    } catch (error) {
        let error_string = "";
        if (error.response.data.message) {
            error_string = error.response.data.message;
        } else {
            error_string = error;
        }
        $toast.error(error_string);
    }
};
</script>
