<template>
    <tr :class="isEvenRow ? 'bg-gray-lighter' : 'bg-white'" class="h-20" :key="index">
        <td>
            <div class="p-4">
                <div>{{ daytour_order.order.customer.name }}</div>
                <div class="text-sm">{{ daytour_order.order.customer.address }}</div>
            </div>
        </td>
        <td>
            <div class="p-4 border-l border-gray-light text-gray-dark flex justify-around items-center gap-4">
                <div class="cursor-pointer" @click="daytour_order.isOpened = !daytour_order.isOpened">
                    <font-awesome-icon
                        icon="fa-solid fa-angle-down"
                        class="transition-transform duration-300"
                        :class="daytour_order.isOpened ? '-rotate-135' : ''"
                    />
                </div>
                <round-button
                    v-if="isToday"
                    @click="emitter.emit('stop-done', daytour_order)"
                    :icon="'fa-solid fa-check'"
                    additional_classes="bg-green-600 border-green-600 hover:bg-green-800"
                    :disabled="!actionsEnabled"
                />
                <round-button
                    v-if="isToday"
                    @click="emitter.emit('stop-cancelled', daytour_order)"
                    :icon="'fa-solid fa-xmark'"
                    additional_classes="bg-red-600 border-red-600 hover:bg-red-800"
                    :disabled="!actionsEnabled"
                />
            </div>
        </td>
    </tr>
    <tr class="bg-gray-light" :class="daytour_order.isOpened ? '' : 'hidden'">
        <td colspan="3">
            <customer-details-table :customer="daytour_order.order.customer" :order="daytour_order.order" :show-transaction-buttons="true" />
        </td>
    </tr>
</template>

<script setup>
import { defineProps, inject, toRefs } from "vue";
import RoundButton from "@/components/global/RoundButton.vue";
import CustomerDetailsTable from "@/components/partials/customers/CustomerDetailsTable.vue";

const emitter = inject("emitter");

const props = defineProps({ daytour_order: Object, isEvenRow: Boolean, actionsEnabled: Boolean, isToday: Boolean, index: Number });
const { daytour_order, isEvenRow, actionsEnabled, isToday, index } = toRefs(props);
</script>
