<template>
    <table class="w-full" v-if="customers">
        <tr class="text-left uppercase text-sm text-white bg-gray-dark">
            <th class="p-4">Name</th>
            <th class="p-4">Aktionen</th>
        </tr>
        <template v-for="(customer, index) in customers" :key="index">
            <customer-table-row :customer="customer" :index="index" />
        </template>
    </table>
</template>

<script setup>
import { defineProps, toRefs } from "vue";

import CustomerTableRow from "@/components/partials/customers/CustomerTableRow.vue";

const props = defineProps({
    customers: Array,
});

const { customers } = toRefs(props);
</script>
