<template>
    <div class="mb-4">
        <input
            :id="uuid"
            type="checkbox"
            v-model="inputComputed"
            class="apperance-none bg-white text-black border p-3 checked:bg-purple focus:outline-none focus:border-purple focus:ring-0 transition-colors duration-300"
            :class="error !== '' ? 'border-red-500' : 'border-gray'"
            :disabled="disabled"
        />
        <label :for="uuid" class="uppercase tracking-wide text-xs font-bold mb-2 ml-2" v-if="label">{{ label }}</label>
        <div v-if="error" class="text-red-500 text-sm text-right px-1">{{ error }}</div>
    </div>
</template>

<script setup>
import { onBeforeMount, defineProps, defineEmits, toRefs, computed } from "vue";

const emit = defineEmits(["update:input"]);
const props = defineProps({
    label: String,
    input: Boolean,
    error: String,
    disabled: { type: Boolean, default: false },
});

const { label, input, error } = toRefs(props);

const inputComputed = computed({
    get: () => input.value,
    set: (val) => emit("update:input", val),
});

let uuid = 0;
onBeforeMount(() => {
    uuid = Math.random().toString().substring(2);
});
</script>

<style></style>
