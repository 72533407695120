<template>
    <draggable
        class="list-group w-full"
        tag="table"
        :component-data="{
            type: 'transition-group',
        }"
        v-model="_customers"
        item-key="order"
        handle=".handle"
        :animation="250"
        @end="orderChanged"
    >
        <template #item="{ element }">
            <tbody :class="element.tour_id !== tour_id ? 'opacity-50' : ''">
                <customer-table-row :sortable="element.tour_id === tour_id" :customer="element" />
            </tbody>
        </template>
    </draggable>
</template>

<script setup>
import axios from "axios";
import { defineProps, ref, onMounted, toRefs, watch } from "vue";
import draggable from "vuedraggable";

import CustomerTableRow from "../customers/CustomerTableRow.vue";

const props = defineProps({ customers: Object, tour_id: Number });

const { customers, tour_id } = toRefs(props);

// const table_row_index = ref(0);
const _customers = ref(null);

watch(customers, async () => {
    _customers.value = customers.value;
});

onMounted(() => {
    _customers.value = customers.value;
});

const orderChanged = () => {
    const sort_order = _customers.value.map((customer) => {
        return customer.id;
    });

    axios.post("tour_customers/" + tour_id.value + "/sortorder?_method=PUT", { new_order: sort_order });
};
</script>
<style>
.fade-move,
.fade-enter-active,
.fade-leave-active {
    @apply transition-all duration-1000;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.fade-leave-to {
    @apply opacity-0 translate-x-full;
}
.fade-enter-from {
    @apply opacity-0 translate-y-full;
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.fade-leave-active {
    @apply absolute;
}
</style>
