<template>
    <div>
        <div class="flex gap-4 m-8 mb-4 mt-0 flex-wrap">
            <template v-for="(orders, index) in weekdata.days" :key="index">
                <div
                    class="text-xl font-bold tracking-widest cursor-pointer mb-4"
                    :class="orders.length ? '' : 'text-gray-lighter'"
                    :id="'day-tab-' + weekdata.year + '-' + weekdata.week + '-' + index"
                >
                    {{
                        dayjs()
                            .day(index + 1)
                            .locale("de")
                            .format("dd")
                    }}
                </div>
            </template>
        </div>

        <template v-for="(orders, index) in weekdata.days" :key="index">
            <div :id="'day-content-' + weekdata.year + '-' + weekdata.week + '-' + index" class="w-full">
                <day-content :orders="orders" :tours="tours" />
            </div>
        </template>
    </div>
</template>

<script setup>
import { defineEmits, defineProps, onMounted, toRefs } from "vue";
import { Tabs } from "flowbite";
import dayjs from "dayjs";

import DayContent from "@/components/partials/orders/DayContent.vue";

require("dayjs/locale/de");
const isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);

const emit = defineEmits(["tab-changed", "mounted"]);
const props = defineProps({
    weekdata: Object,
    tours: Object,
});

const { weekdata, tours } = toRefs(props);

onMounted(() => {
    let tabElements = [];

    weekdata.value.days.forEach((orders, index) => {
        tabElements.push({
            id: dayjs()
                .year(weekdata.value.year)
                .isoWeek(weekdata.value.week)
                .isoWeekday(index + 1)
                .format("YYYY-MM-DD"),
            triggerEl: document.querySelector("#day-tab-" + weekdata.value.year + "-" + weekdata.value.week + "-" + index),
            targetEl: document.querySelector("#day-content-" + weekdata.value.year + "-" + weekdata.value.week + "-" + index),
        });
    });

    const options = {
        defaultTabId:
            weekdata.value.year == dayjs().year() && weekdata.value.week == dayjs().isoWeek()
                ? dayjs().format("YYYY-MM-DD")
                : dayjs().year(weekdata.value.year).isoWeek(weekdata.value.week).isoWeekday(1).format("YYYY-MM-DD"),
        activeClasses: "text-black border-b-4 border-purple",
        inactiveClasses: "text-gray-light",
        onShow: (tabs) => {
            emit("tab-changed", tabs);
        },
    };

    const tabs = new Tabs(tabElements, options);

    emit("mounted", tabs);
});

// const tour_orders = (orders, tour_id) => {
//     return orders.filter((order) => order.tour_id === tour_id);
// };
</script>

<style></style>
