<template>
    <div class="flex justify-between items-center gap-8">
        <div class="uppercase text-xl font-bold tracking-widest">Menu</div>
        <Slide right :closeOnNavigation="false" :isOpen="isOpen" class="flex gap-4 text-white whitespace-nowrap" ref="mainMenu" @openMenu="isOpen = true">
            <menu-item v-for="(menuItem, index) in menuItems" :key="index" :menuItem="menuItem" @link-clicked="isOpen = false" />
        </Slide>
    </div>
</template>

<script setup>
import axios from "axios";
import { ref, onMounted } from "vue";
import { Slide } from "vue3-burger-menu";
import MenuItem from "./MenuItem.vue";

const mainMenu = ref(null);
const isOpen = ref(false);
const menuItems = ref([]);

onMounted(async () => {
    let tours = null;
    let tours_children = [];
    let daytours_children = [];

    tours = (await axios.get("tours")).data;

    tours.forEach((tour) => {
        tours_children.push({
            text: tour.name,
            path: "/customers/tour/" + tour.id,
        });
        daytours_children.push({
            text: tour.name,
            path: "/daytour/" + tour.id + "?admin=1",
        });
    });

    menuItems.value = [
        {
            text: "Übersicht",
            path: "/dashboard",
        },
        {
            text: "Wochenmenü",
            path: "/weekmenu",
        },
        {
            text: "Kunden",
            children: [
                {
                    text: "Alle",
                    path: "/customers",
                },
                {
                    text: "Touren",
                    children: tours_children,
                },
                {
                    text: "Im Zahlungsrückstand",
                    path: "/customers/outstanding_payments",
                },
            ],
        },
        {
            text: "Bestellungen",
            path: "/orders",
        },
        {
            text: "Touren",
            children: daytours_children,
        },
        {
            text: "Auswertung",
            children: [
                {
                    text: "Gerichte",
                    path: "/analysis/meals",
                },
                {
                    text: "Zahlungen",
                    path: "/analysis/payments",
                },
            ],
        },
        {
            text: "Ausloggen",
            path: "/logout",
        },
    ];
});
</script>
