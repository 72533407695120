<template>
    <div class="mb-4 border-b">
        <div class="flex gap-2 items-center">
            <textarea-input
                :label="label"
                :rows="1"
                placeholder="Name"
                v-model:input="edit_meal.name"
                inputType="text"
                :error="errors.name ? errors.name[0] : ''"
                :autoheight="true"
                class="grow"
            />
            <font-awesome-icon
                :class="edit_meal.hasOrders ? 'text-gray-light' : 'text-red-700 cursor-pointer'"
                icon="fa-solid fa-trash-alt"
                @click="edit_meal.hasOrders ? void 0 : emit('delete')"
            />
        </div>
        <div class="flex gap-2 items-end justify-between">
            <text-input
                class="basis-32"
                label="Preis"
                inputType="number"
                :min="0.01"
                :step="0.01"
                v-model:input="edit_meal.price"
                :error="errors.price ? errors.price[0] : ''"
            />
            <checkbox-input label="veggie" v-model:input="edit_meal.is_veggie" :error="errors.is_veggie ? errors.is_veggie[0] : ''" class="text-right" />
        </div>
        <div v-if="errors.delete" class="text-red-500 text-sm text-right px-1">{{ errors.delete }}</div>
    </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, toRefs } from "vue";

import TextInput from "@/components/global/TextInput.vue";
import TextareaInput from "@/components/global/TextareaInput.vue";
import CheckboxInput from "@/components/global/CheckboxInput.vue";

const emit = defineEmits(["delete"]);
const props = defineProps({
    label: String,
    edit_meal: Object,
});
const { label, edit_meal } = toRefs(props);

const errors = ref([]);
</script>

<style></style>
