<template>
    <font-awesome-icon icon="fa-solid fa-truck-arrow-right" @click.stop="showDropDown = !showDropDown" />
    <div v-if="showDropDown" class="absolute top-12 left-0 bg-purple text-white z-10 p-4 shadow-xl">
        <div v-for="(tour, index) in tours" :key="index" @click="emit('moveOrder', tour)">{{ tour.name }}</div>
    </div>
</template>

<script setup>
import { defineEmits, defineProps, onBeforeUnmount, onMounted, ref, toRefs } from "vue";

const props = defineProps({ tours: Object });
const { tours } = toRefs(props);

const emit = defineEmits(["moveOrder"]);

const showDropDown = ref(false);

const closeDropDown = () => {
    showDropDown.value = false;
};

onMounted(() => {
    document.addEventListener("click", closeDropDown);
});
onBeforeUnmount(() => {
    document.removeEventListener("click", closeDropDown);
});
</script>
