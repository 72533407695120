<template>
    <div
        id="menu-modal"
        tabindex="-1"
        aria-hidden="true"
        class="fixed top-0 left-0 right-0 z-50 hidden w-full py-16 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full"
    >
        <div v-if="menu" class="relative w-full h-full max-w-md md:h-auto">
            <!-- Modal content -->
            <div class="relative bg-white shadow">
                <button
                    type="button"
                    class="absolute top-3 right-2.5 text-gray bg-transparent hover:bg-gray-light hover:text-gray-dark text-sm p-1.5 ml-auto inline-flex items-center"
                    @click="closeModal"
                >
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
                <div class="px-6 py-6 lg:px-8">
                    <template v-for="(edit_meal, index) in menu.meals" :key="index">
                        <meal-edit-row
                            v-if="typeof edit_meal === 'object'"
                            :edit_meal="edit_meal"
                            :label="'Gericht&nbsp;' + (parseInt(index) + 1)"
                            @delete="deleteMeal(edit_meal)"
                        />
                    </template>

                    <button
                        class="font-montserrat text-xs block w-full bg-purple hover:bg-black text-white rounded-sm py-3 mb-2 transition-colors duration-300 uppercase tracking-widest font-bold"
                        @click="addMeal"
                    >
                        <font-awesome-icon icon="fa-solid fa-plus" />
                        &nbsp;Gericht hinzufügen
                    </button>
                    <button
                        class="font-montserrat text-xs block w-full bg-purple-dark hover:bg-black text-white rounded-sm py-3 transition-colors duration-300 uppercase tracking-widest font-bold"
                        type="submit"
                        @click="saveMenu"
                    >
                        <font-awesome-icon icon="fa-solid fa-plus" />
                        &nbsp;Menü speichern
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, inject, onUpdated, ref, toRefs } from "vue";
import axios from "axios";

import MealEditRow from "@/components/partials/modals/MealEditRow.vue";
import dayjs from "dayjs";

const props = defineProps({
    menu: Object,
});

const { menu } = toRefs(props);

const emitter = inject("emitter");

const errors = ref([]);
const meals_to_delete = ref([]);

onUpdated(() => {
    if (!menu.value.meals.length) addMeal();
});

const addMeal = () => {
    menu.value.meals.push({ price: 8 });
};

const deleteMeal = async (meal_to_delete) => {
    if (meal_to_delete.id) {
        meals_to_delete.value.push(meal_to_delete);
    }
    menu.value.meals = menu.value.meals.filter((meal) => meal !== meal_to_delete);
};

const saveMenu = async () => {
    errors.value = [];

    try {
        if (menu.value.id) {
            let data = {
                menu_id: menu.value.id,
                meals: menu.value.meals,
                meals_to_delete: meals_to_delete.value,
                date: dayjs(menu.value.date).format("YYYY-MM-DD"),
            };
            await axios.post("menu/" + menu.value.id + "?_method=PUT", data);
        } else {
            let data = {
                meals: menu.value.meals,
                date: dayjs(menu.value.date).format("YYYY-MM-DD"),
            };
            await axios.post("menu", data);
        }

        emitter.emit("menu-saved", menu.value);
        closeModal();
    } catch (error) {
        errors.value = error.response.data.errors;
    }
};

const closeModal = () => {
    meals_to_delete.value = [];
    emitter.emit("close-modal");
};
</script>

<style></style>
