<template>
    <tr :class="index % 2 == 0 ? 'bg-gray-lighter' : 'bg-white'" class="h-20">
        <td>
            <div class="p-4 flex flex-row">
                <div v-if="sortable" class="handle self-stretch basis-12 flex justify-center items-center">
                    <font-awesome-icon icon="fa-solid fa-grip" />
                </div>
                <div>
                    <div v-html="customer.name" />
                    <div class="text-sm" v-html="customer.address" />
                    <div class="text-sm flex gap-1">
                        <div v-for="(tour, index) in customer.tours" :key="index">
                            <span v-html="tour.name" :class="tour.pivot.is_default ? 'italic text-purple' : ''" />
                        </div>
                    </div>
                </div>
            </div>
        </td>
        <td>
            <div class="p-4 border-l border-gray-light text-gray-dark flex justify-around items-center gap-4">
                <div class="cursor-pointer" @click="toggleIsOpened">
                    <font-awesome-icon
                        icon="fa-solid fa-angle-down"
                        class="transition-transform duration-300"
                        :class="customer.isOpened ? '-rotate-135' : ''"
                    />
                </div>
                <round-button
                    @click="customer.isOpened ? emitter.emit('open-customer-modal', customer) : void 0"
                    :icon="'fa-solid fa-pencil-alt'"
                    :disabled="!customer.isOpened"
                />

                <round-button :icon="'fa-solid fa-info'" @click="router.push('/customer/' + customer.id)" />
            </div>
        </td>
    </tr>
    <tr v-if="customer.notes && false" :class="index % 2 == 0 ? 'bg-gray-lighter' : 'bg-white'">
        <td colspan="4">
            <div class="text-sm mx-4 text-right whitespace-pre-line" v-html="customer.notes"></div>
        </td>
    </tr>
    <tr class="bg-gray-light" :class="customer.isOpened ? '' : 'hidden'">
        <td colspan="4">
            <customer-details-table :customer="customer" :show-tour="true" :show-transaction-buttons="true" :class="customer.isOpened ? '' : 'hidden'" />
        </td>
    </tr>
</template>

<script setup>
import { defineProps, inject, toRefs } from "vue";
import { useRouter } from "vue-router";

// import axios from "axios";

import RoundButton from "@/components/global/RoundButton.vue";
import CustomerDetailsTable from "./CustomerDetailsTable.vue";

const props = defineProps({
    customer: Object,
    index: Number,
    sortable: {
        Type: Boolean,
        default: false,
    },
});
const { customer, index, sortable } = toRefs(props);

const emitter = inject("emitter");

const router = useRouter();

const toggleIsOpened = () => {
    if (typeof customer.value.isOpened === "undefined") customer.value.isOpened = false;
    customer.value.isOpened = !customer.value.isOpened;
};
</script>

<style></style>
