<template>
    <template v-if="isDragging">
        <tr>
            <td class="text-sm">{{ order.customer.name }} / {{ order.customer.address }}</td>
        </tr>
    </template>

    <template v-else>
        <tr class="bg-gray-lighter h-20">
            <td>
                <div class="p-4 flex flex-row">
                    <div v-if="sortable" class="handle cursor-grab self-stretch basis-12 flex justify-center items-center">
                        <font-awesome-icon icon="fa-solid fa-grip" />
                    </div>
                    <div v-if="sortable && tours" class="relative cursor-pointer self-stretch basis-12 flex justify-center items-center">
                        <move-order-to-tour :tours="tours.filter((t) => t.id !== order.tour_id)" @move-order="(tour) => moveOrder(tour)" />
                    </div>
                    <div>
                        {{ order.customer.name }}
                        <div class="text-sm">{{ order.customer.address }}</div>
                    </div>
                </div>
            </td>
            <td>
                <div class="p-4 border-l border-gray-light text-gray-dark flex justify-around items-center gap-4">
                    <div class="cursor-pointer" @click="order.isOpened = !order.isOpened">
                        <font-awesome-icon
                            icon="fa-solid fa-angle-down"
                            class="transition-transform duration-300"
                            :class="order.isOpened ? '-rotate-135' : ''"
                        />
                    </div>
                    <round-button
                        @click="order.isOpened ? emitter.emit('open-order-modal', order) : void 0"
                        :icon="'fa-solid fa-pencil-alt'"
                        :disabled="!order.isOpened"
                    />
                </div>
            </td>
        </tr>
        <tr v-if="order.notes && false" class="bg-gray-lighter">
            <td colspan="4">
                <div class="text-sm mx-4 text-right whitespace-pre-line">{{ order.notes }}</div>
            </td>
        </tr>
        <tr :id="'order-details-' + order.id" class="bg-gray-light" :class="order.isOpened ? '' : 'hidden'">
            <td colspan="4">
                <div class="flex flex-col">
                    <div class="mx-4 py-4 flex">
                        <div>
                            <font-awesome-icon icon="fa-solid fa-map-marker" class="mr-2" />
                        </div>
                        <div>
                            <div>{{ order.customer.name }}</div>
                            <div>{{ order.customer.address }}</div>
                            <!-- <div>({{ order.customer.tour.name }})</div> -->

                            <div v-if="order.customer.notes" class="text-sm whitespace-pre-line">({{ order.customer.notes }})</div>
                        </div>
                    </div>
                    <div class="mx-4 py-4 border-t border-gray flex">
                        <div>
                            <font-awesome-icon icon="fa-solid fa-map" class="mr-2" />
                        </div>
                        <div>
                            <div v-for="(ordermeal, index) in order.ordermeals" :key="index" class="flex gap-2">
                                <div>{{ ordermeal.quantity }}x</div>
                                <div>
                                    {{ ordermeal.meal.name }}
                                    <font-awesome-icon v-if="ordermeal.meal.is_veggie" class="text-green-600" icon="fa-solid fa-leaf" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mx-4 py-4 border-gray flex" v-if="order.notes">
                        <div>
                            <font-awesome-icon icon="fa-solid fa-circle-info" class="mr-2" />
                        </div>
                        <div v-if="order.notes" class="whitespace-pre-line">
                            {{ order.notes }}
                        </div>
                    </div>

                    <div class="mx-4 py-4">
                        <button
                            class="font-montserrat text-xs block w-full bg-purple hover:bg-black text-white py-3 transition-colors duration-300 uppercase tracking-widest font-bold"
                            type="submit"
                        >
                            <font-awesome-icon icon="fa-solid fa-xmark" />
                            &nbsp;Bestellung löschen
                        </button>
                    </div>
                </div>
            </td>
        </tr>
    </template>
</template>

<script setup>
import { defineProps, inject, toRefs } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import RoundButton from "@/components/global/RoundButton.vue";
import MoveOrderToTour from "./MoveOrderToTour.vue";

const emitter = inject("emitter");

const props = defineProps({
    order: Object,
    tours: Object,
    sortable: {
        Type: Boolean,
        default: false,
    },
    isDragging: {
        Type: Boolean,
        default: false,
    },
});

const { order, tours, sortable, isDragging } = toRefs(props);

const moveOrder = (tour) => {
    order.value.tour_id = tour.id;
    emitter.emit("order-moved");
};
</script>
