<template>
    <div class="max-w-xs text-center mx-auto p-8">
        <div v-if="errors.length" class="text-red-600">{{ errors }}</div>
        <div v-else>Benutzer wird abgemeldet...</div>
    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import { useUserStore } from "../store/user-store";
import { useTitleStore } from "@/store/title-store";

const titleStore = useTitleStore();
titleStore.setTitle("Logout");

const userStore = useUserStore();
let errors = ref([]);

onMounted(() => {
    logout();
});

const logout = () => {
    try {
        axios.post("logout");

        userStore.clearUser();
        window.location.reload();
    } catch (error) {
        errors.value = error.response.data.error;
    }
};
</script>
